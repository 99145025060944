/*
File generated by js-routes 1.4.14
Based on Rails 7.0.8.3 routes of Malinko::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// account => /accounts/:id(.:format)
  // function(id, options)
  account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_account_file => /accounts/:account_id/account_files/:id(.:format)
  // function(account_id, id, options)
  account_account_file_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_account_files => /accounts/:account_id/account_files(.:format)
  // function(account_id, options)
  account_account_files_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_healthroster_config => /account_healthroster_config(.:format)
  // function(options)
  account_healthroster_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_healthroster_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// account_locked => /account_locked(.:format)
  // function(options)
  account_locked_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_locked",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// accounts => /accounts(.:format)
  // function(options)
  accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// accounts_switch => /accounts/switch(.:format)
  // function(options)
  accounts_switch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"switch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// activities => /activities(.:format)
  // function(options)
  activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// activity => /activities/:id(.:format)
  // function(id, options)
  activity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// acuity_dashboard_historic => /acuity_dashboard/historic(.:format)
  // function(options)
  acuity_dashboard_historic_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"acuity_dashboard",false],[2,[7,"/",false],[2,[6,"historic",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// acuity_dashboard_today => /acuity_dashboard/today(.:format)
  // function(options)
  acuity_dashboard_today_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"acuity_dashboard",false],[2,[7,"/",false],[2,[6,"today",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// acuity_scores_configs => /acuity_scores_configs(.:format)
  // function(options)
  acuity_scores_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"acuity_scores_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// add_user_field => /add_user_field(.:format)
  // function(options)
  add_user_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"add_user_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_account => /admin/accounts/:id(.:format)
  // function(id, options)
  admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_account_account_file => /admin/accounts/:account_id/account_files/:id(.:format)
  // function(account_id, id, options)
  admin_account_account_file_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_account_files => /admin/accounts/:account_id/account_files(.:format)
  // function(account_id, options)
  admin_account_account_files_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_acuity_score => /admin/accounts/:account_id/acuity_scores/:id(.:format)
  // function(account_id, id, options)
  admin_account_acuity_score_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"acuity_scores",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_acuity_scores => /admin/accounts/:account_id/acuity_scores(.:format)
  // function(account_id, options)
  admin_account_acuity_scores_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"acuity_scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_alert_config => /admin/accounts/:account_id/alert_configs/:id(.:format)
  // function(account_id, id, options)
  admin_account_alert_config_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"alert_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_alert_configs => /admin/accounts/:account_id/alert_configs(.:format)
  // function(account_id, options)
  admin_account_alert_configs_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"alert_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_autoscheduling_setting => /admin/accounts/:account_id/autoscheduling_settings/:id(.:format)
  // function(account_id, id, options)
  admin_account_autoscheduling_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"autoscheduling_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_autoscheduling_settings => /admin/accounts/:account_id/autoscheduling_settings(.:format)
  // function(account_id, options)
  admin_account_autoscheduling_settings_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"autoscheduling_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_availability_import_config => /admin/accounts/:account_id/availability_import_configs/:id(.:format)
  // function(account_id, id, options)
  admin_account_availability_import_config_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"availability_import_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_availability_import_configs => /admin/accounts/:account_id/availability_import_configs(.:format)
  // function(account_id, options)
  admin_account_availability_import_configs_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"availability_import_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_call_monitoring_setting => /admin/accounts/:account_id/call_monitoring_settings/:id(.:format)
  // function(account_id, id, options)
  admin_account_call_monitoring_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_call_monitoring_settings => /admin/accounts/:account_id/call_monitoring_settings(.:format)
  // function(account_id, options)
  admin_account_call_monitoring_settings_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_check_ip_address => /admin/accounts/:account_id/check_ip_address(.:format)
  // function(account_id, options)
  admin_account_check_ip_address_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"check_ip_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_client => /admin/accounts/:account_id/clients/:id(.:format)
  // function(account_id, id, options)
  admin_account_client_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_clients => /admin/accounts/:account_id/clients(.:format)
  // function(account_id, options)
  admin_account_clients_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_create_allowed_ip => /admin/accounts/:account_id/create_allowed_ip(.:format)
  // function(account_id, options)
  admin_account_create_allowed_ip_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"create_allowed_ip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_create_certificate => /admin/accounts/:account_id/certificate(.:format)
  // function(account_id, options)
  admin_account_create_certificate_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"certificate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_custom_field => /admin/accounts/:account_id/custom_fields/:id(.:format)
  // function(account_id, id, options)
  admin_account_custom_field_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_custom_field_custom_field_value => /admin/accounts/:account_id/custom_fields/:custom_field_id/custom_field_values/:id(.:format)
  // function(account_id, custom_field_id, id, options)
  admin_account_custom_field_custom_field_value_path: Utils.route([["account_id",true],["custom_field_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"custom_field_id",false],[2,[7,"/",false],[2,[6,"custom_field_values",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// admin_account_custom_field_custom_field_values => /admin/accounts/:account_id/custom_fields/:custom_field_id/custom_field_values(.:format)
  // function(account_id, custom_field_id, options)
  admin_account_custom_field_custom_field_values_path: Utils.route([["account_id",true],["custom_field_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"custom_field_id",false],[2,[7,"/",false],[2,[6,"custom_field_values",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_account_custom_field_values_update => /admin/accounts/:account_id/custom_field_values_update(.:format)
  // function(account_id, options)
  admin_account_custom_field_values_update_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_field_values_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_custom_fields => /admin/accounts/:account_id/custom_fields(.:format)
  // function(account_id, options)
  admin_account_custom_fields_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_destroy_allowed_ip => /admin/accounts/:account_id/destroy_allowed_ip(.:format)
  // function(account_id, options)
  admin_account_destroy_allowed_ip_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"destroy_allowed_ip",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_import => /admin/accounts/:account_id/import/:id(.:format)
  // function(account_id, id, options)
  admin_account_import_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_import_index => /admin/accounts/:account_id/import(.:format)
  // function(account_id, options)
  admin_account_import_index_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_ip_restriction_configuration => /admin/accounts/:account_id/ip_restriction_configuration(.:format)
  // function(account_id, options)
  admin_account_ip_restriction_configuration_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"ip_restriction_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_item => /admin/accounts/:account_id/items/:id(.:format)
  // function(account_id, id, options)
  admin_account_item_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_items => /admin/accounts/:account_id/items(.:format)
  // function(account_id, options)
  admin_account_items_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_list_pem => /admin/accounts/:account_id/certificate(.:format)
  // function(account_id, options)
  admin_account_list_pem_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"certificate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_lock => /admin/accounts/:account_id/lock(.:format)
  // function(account_id, options)
  admin_account_lock_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"lock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_mark_for_deletion => /admin/accounts/:account_id/mark_for_deletion(.:format)
  // function(account_id, options)
  admin_account_mark_for_deletion_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"mark_for_deletion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_message => /admin/accounts/:account_id/messages/:id(.:format)
  // function(account_id, id, options)
  admin_account_message_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_message_clear => /admin/accounts/:account_id/messages/:message_id/clear(.:format)
  // function(account_id, message_id, options)
  admin_account_message_clear_path: Utils.route([["account_id",true],["message_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"clear",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_account_message_resolve => /admin/accounts/:account_id/messages/:message_id/resolve(.:format)
  // function(account_id, message_id, options)
  admin_account_message_resolve_path: Utils.route([["account_id",true],["message_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"message_id",false],[2,[7,"/",false],[2,[6,"resolve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_account_messages => /admin/accounts/:account_id/messages(.:format)
  // function(account_id, options)
  admin_account_messages_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_messages_bulk_update => /admin/accounts/:account_id/messages_bulk_update(.:format)
  // function(account_id, options)
  admin_account_messages_bulk_update_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages_bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_mobile_app_setting => /admin/accounts/:account_id/mobile_app_settings/:id(.:format)
  // function(account_id, id, options)
  admin_account_mobile_app_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"mobile_app_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_mobile_app_settings => /admin/accounts/:account_id/mobile_app_settings(.:format)
  // function(account_id, options)
  admin_account_mobile_app_settings_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"mobile_app_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_reset_admin => /admin/accounts/:account_id/reset_admin(.:format)
  // function(account_id, options)
  admin_account_reset_admin_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"reset_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_routing_setting => /admin/accounts/:account_id/routing_settings/:id(.:format)
  // function(account_id, id, options)
  admin_account_routing_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"routing_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_routing_settings => /admin/accounts/:account_id/routing_settings(.:format)
  // function(account_id, options)
  admin_account_routing_settings_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"routing_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_setting => /admin/accounts/:account_id/settings/:id(.:format)
  // function(account_id, id, options)
  admin_account_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_settings => /admin/accounts/:account_id/settings(.:format)
  // function(account_id, options)
  admin_account_settings_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_sftp_setting => /admin/accounts/:account_id/sftp_settings/:id(.:format)
  // function(account_id, id, options)
  admin_account_sftp_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"sftp_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_sftp_settings => /admin/accounts/:account_id/sftp_settings(.:format)
  // function(account_id, options)
  admin_account_sftp_settings_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"sftp_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_taxonomies => /admin/accounts/:account_id/taxonomies(.:format)
  // function(account_id, options)
  admin_account_taxonomies_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"taxonomies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_taxonomy => /admin/accounts/:account_id/taxonomies/:id(.:format)
  // function(account_id, id, options)
  admin_account_taxonomy_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"taxonomies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_textmarketer_config => /admin/accounts/:account_id/textmarketer/configs/:id(.:format)
  // function(account_id, id, options)
  admin_account_textmarketer_config_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"textmarketer",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_account_textmarketer_configs => /admin/accounts/:account_id/textmarketer/configs(.:format)
  // function(account_id, options)
  admin_account_textmarketer_configs_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"textmarketer",false],[2,[7,"/",false],[2,[6,"configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_toggle_ip_restrictions => /admin/accounts/:account_id/toggle_ip_restrictions(.:format)
  // function(account_id, options)
  admin_account_toggle_ip_restrictions_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"toggle_ip_restrictions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_unlock => /admin/accounts/:account_id/unlock(.:format)
  // function(account_id, options)
  admin_account_unlock_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_unmark_for_deletion => /admin/accounts/:account_id/unmark_for_deletion(.:format)
  // function(account_id, options)
  admin_account_unmark_for_deletion_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"unmark_for_deletion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_user => /admin/accounts/:account_id/users/:id(.:format)
  // function(account_id, id, options)
  admin_account_user_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_users => /admin/accounts/:account_id/users(.:format)
  // function(account_id, options)
  admin_account_users_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_account_zoho_import => /admin/accounts/:account_id/zoho_import/:id(.:format)
  // function(account_id, id, options)
  admin_account_zoho_import_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"zoho_import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_account_zoho_import_index => /admin/accounts/:account_id/zoho_import(.:format)
  // function(account_id, options)
  admin_account_zoho_import_index_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"zoho_import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_accounts => /admin/accounts(.:format)
  // function(options)
  admin_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_ccg => /admin/ccgs/:id(.:format)
  // function(id, options)
  admin_ccg_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ccgs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_ccgs => /admin/ccgs(.:format)
  // function(options)
  admin_ccgs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ccgs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_malinko_term => /admin/malinko_terms/:id(.:format)
  // function(id, options)
  admin_malinko_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_malinko_terms => /admin/malinko_terms(.:format)
  // function(options)
  admin_malinko_terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_menu => /pages/admin(.:format)
  // function(options)
  admin_menu_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_mobile_app_build => /admin/mobile_app_builds/:id(.:format)
  // function(id, options)
  admin_mobile_app_build_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"mobile_app_builds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_mobile_app_builds => /admin/mobile_app_builds(.:format)
  // function(options)
  admin_mobile_app_builds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"mobile_app_builds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_new_report => /admin/accounts/:account_id/reports/new/:model(.:format)
  // function(account_id, model, options)
  admin_new_report_path: Utils.route([["account_id",true],["model",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[3,"model",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_nhs_reset_demo_account => /admin/nhs/reset_demo_account(.:format)
  // function(options)
  admin_nhs_reset_demo_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"nhs",false],[2,[7,"/",false],[2,[6,"reset_demo_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_postcode => /admin/postcodes/:id(.:format)
  // function(id, options)
  admin_postcode_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"postcodes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_postcodes => /admin/postcodes(.:format)
  // function(options)
  admin_postcodes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"postcodes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_practice => /admin/practices/:id(.:format)
  // function(id, options)
  admin_practice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"practices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_practices => /admin/practices(.:format)
  // function(options)
  admin_practices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"practices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_term => /admin/malinko_terms/:id(.:format)
  // function(id, options)
  admin_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_terms => /admin/malinko_terms(.:format)
  // function(options)
  admin_terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_totp => /admin/totp(.:format)
  // function(options)
  admin_totp_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"totp",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_totp_import => /admin/totp(.:format)
  // function(options)
  admin_totp_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"totp",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_user => /admin/user/:id(.:format)
  // function(id, options)
  admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_user_index => /admin/user(.:format)
  // function(options)
  admin_user_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ajax_add_client_field => /ajax_add_client_field(.:format)
  // function(options)
  ajax_add_client_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ajax_add_client_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ajax_add_episode_of_care_field => /ajax_add_episode_of_care_field(.:format)
  // function(options)
  ajax_add_episode_of_care_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ajax_add_episode_of_care_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ajax_add_overview => /ajax_add_overview(.:format)
  // function(options)
  ajax_add_overview_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ajax_add_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ajax_add_site_default_clients => /ajax_add_site_default_clients(.:format)
  // function(options)
  ajax_add_site_default_clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ajax_add_site_default_clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// all_collections => /reports/collections(.:format)
  // function(options)
  all_collections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// allocate => /events/allocate(.:format)
  // function(options)
  allocate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"allocate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_dashboard_v1_acuity_historic => /api/dashboard/v1/acuity-historic(.:format)
  // function(options)
  api_dashboard_v1_acuity_historic_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"acuity-historic",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_dashboard_v1_acuity_historic_staffing_levels => /api/dashboard/v1/acuity-historic/staffing_levels(.:format)
  // function(options)
  api_dashboard_v1_acuity_historic_staffing_levels_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"acuity-historic",false],[2,[7,"/",false],[2,[6,"staffing_levels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_dashboard_v1_acuity_today => /api/dashboard/v1/acuity-today(.:format)
  // function(options)
  api_dashboard_v1_acuity_today_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"acuity-today",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_dashboard_v1_clear_cache => /api/dashboard/v1/clear-cache(.:format)
  // function(options)
  api_dashboard_v1_clear_cache_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clear-cache",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_dashboard_v1_demand_capacity => /api/dashboard/v1/demand-capacity(.:format)
  // function(options)
  api_dashboard_v1_demand_capacity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"demand-capacity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_dashboard_v1_demand_capacity_trends => /api/dashboard/v1/demand-capacity/trends(.:format)
  // function(options)
  api_dashboard_v1_demand_capacity_trends_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"demand-capacity",false],[2,[7,"/",false],[2,[6,"trends",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_dashboard_v1_groups => /api/dashboard/v1/groups(.:format)
  // function(options)
  api_dashboard_v1_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_dashboard_v1_weekly_planner_chart => /api/dashboard/v1/weekly-planner/chart(.:format)
  // function(options)
  api_dashboard_v1_weekly_planner_chart_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"weekly-planner",false],[2,[7,"/",false],[2,[6,"chart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_dashboard_v1_weekly_planner_daily => /api/dashboard/v1/weekly-planner/daily(.:format)
  // function(options)
  api_dashboard_v1_weekly_planner_daily_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"weekly-planner",false],[2,[7,"/",false],[2,[6,"daily",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_dashboard_v1_weekly_planner_interventions => /api/dashboard/v1/weekly-planner/interventions(.:format)
  // function(options)
  api_dashboard_v1_weekly_planner_interventions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"weekly-planner",false],[2,[7,"/",false],[2,[6,"interventions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hl7_v2 => /api/hl7/v2(.:format)
  // function(options)
  api_hl7_v2_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hl7",false],[2,[7,"/",false],[2,[6,"v2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_rest_docs => /api/rest/docs(.:format)
  // function(options)
  api_rest_docs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_rest_v1 => /api/rest/v1/openapi.json(.:format)
  // function(options)
  api_rest_v1_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"openapi",false],[2,[8,".",false],[2,[6,"json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_client => /api/rest/v1/clients/:id(.:format)
  // function(id, options)
  api_rest_v1_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_client_event => /api/rest/v1/clients/:client_id/events/:id(.:format)
  // function(client_id, id, options)
  api_rest_v1_client_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_rest_v1_client_events => /api/rest/v1/clients/:client_id/events(.:format)
  // function(client_id, options)
  api_rest_v1_client_events_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_rest_v1_clients => /api/rest/v1/clients(.:format)
  // function(options)
  api_rest_v1_clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v1_event => /api/rest/v1/events/:id(.:format)
  // function(id, options)
  api_rest_v1_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_events => /api/rest/v1/events(.:format)
  // function(options)
  api_rest_v1_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v1_events_users_allocated => /api/rest/v1/events/users/allocated(.:format)
  // function(options)
  api_rest_v1_events_users_allocated_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"allocated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_rest_v1_events_users_unallocated => /api/rest/v1/events/users/unallocated(.:format)
  // function(options)
  api_rest_v1_events_users_unallocated_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unallocated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_rest_v1_item => /api/rest/v1/items/:id(.:format)
  // function(id, options)
  api_rest_v1_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_items => /api/rest/v1/items(.:format)
  // function(options)
  api_rest_v1_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v1_programme => /api/rest/v1/programmes/:id(.:format)
  // function(id, options)
  api_rest_v1_programme_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_programmes => /api/rest/v1/programmes(.:format)
  // function(options)
  api_rest_v1_programmes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"programmes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v1_response => /api/rest/v1/responses/:id(.:format)
  // function(id, options)
  api_rest_v1_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_responses => /api/rest/v1/responses(.:format)
  // function(options)
  api_rest_v1_responses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v1_user => /api/rest/v1/users/:id(.:format)
  // function(id, options)
  api_rest_v1_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v1_users => /api/rest/v1/users(.:format)
  // function(options)
  api_rest_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v2 => /api/rest/v2/openapi.json(.:format)
  // function(options)
  api_rest_v2_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"openapi",false],[2,[8,".",false],[2,[6,"json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_client => /api/rest/v2/clients/:id(.:format)
  // function(id, options)
  api_rest_v2_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_client_event => /api/rest/v2/clients/:client_id/events/:id(.:format)
  // function(client_id, id, options)
  api_rest_v2_client_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_rest_v2_client_events => /api/rest/v2/clients/:client_id/events(.:format)
  // function(client_id, options)
  api_rest_v2_client_events_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_rest_v2_clients => /api/rest/v2/clients(.:format)
  // function(options)
  api_rest_v2_clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v2_event => /api/rest/v2/events/:id(.:format)
  // function(id, options)
  api_rest_v2_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_events => /api/rest/v2/events(.:format)
  // function(options)
  api_rest_v2_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v2_events_users_allocated => /api/rest/v2/events/users/allocated(.:format)
  // function(options)
  api_rest_v2_events_users_allocated_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"allocated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_rest_v2_events_users_unallocated => /api/rest/v2/events/users/unallocated(.:format)
  // function(options)
  api_rest_v2_events_users_unallocated_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unallocated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_rest_v2_item => /api/rest/v2/items/:id(.:format)
  // function(id, options)
  api_rest_v2_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_items => /api/rest/v2/items(.:format)
  // function(options)
  api_rest_v2_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v2_programme => /api/rest/v2/programmes/:id(.:format)
  // function(id, options)
  api_rest_v2_programme_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_programmes => /api/rest/v2/programmes(.:format)
  // function(options)
  api_rest_v2_programmes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"programmes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v2_response => /api/rest/v2/responses/:id(.:format)
  // function(id, options)
  api_rest_v2_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_responses => /api/rest/v2/responses(.:format)
  // function(options)
  api_rest_v2_responses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_rest_v2_user => /api/rest/v2/users/:id(.:format)
  // function(id, options)
  api_rest_v2_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_rest_v2_users => /api/rest/v2/users(.:format)
  // function(options)
  api_rest_v2_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3 => /api/v3/attendances/:event_id/update(.:format)
  // function(event_id, options)
  api_v3_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"attendances",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v3_bootstrap => /api/v3/bootstrap(.:format)
  // function(options)
  api_v3_bootstrap_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"bootstrap",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_error => /api/v3/error(.:format)
  // function(options)
  api_v3_error_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_messages_create => /api/v3/messages/create(.:format)
  // function(options)
  api_v3_messages_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_silent => /api/v3/silent(.:format)
  // function(options)
  api_v3_silent_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"silent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// archive_item => /i/:id/archive(.:format)
  // function(id, options)
  archive_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// archive_unarchive_did_not_attend_reason => /did_not_attend_reasons/:id/archive_unarchive(.:format)
  // function(id, options)
  archive_unarchive_did_not_attend_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"did_not_attend_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive_unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// associate_to_group => /associate_to_group(.:format)
  // function(options)
  associate_to_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"associate_to_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// attendance => /attendances/:id(.:format)
  // function(id, options)
  attendance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"attendances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attendances => /attendances(.:format)
  // function(options)
  attendances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"attendances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// attendances_bulk_update => /attendances/bulk_update(.:format)
  // function(options)
  attendances_bulk_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"attendances",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// auto_allocate => /auto_allocate(.:format)
  // function(options)
  auto_allocate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_allocate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// auto_schedule_result => /auto_schedule_results/:id(.:format)
  // function(id, options)
  auto_schedule_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_schedule_results",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// auto_schedule_results => /auto_schedule_results(.:format)
  // function(options)
  auto_schedule_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_schedule_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// auto_schedule_worker => /auto_schedule_workers/:id(.:format)
  // function(id, options)
  auto_schedule_worker_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_schedule_workers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// auto_schedule_workers => /auto_schedule_workers(.:format)
  // function(options)
  auto_schedule_workers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_schedule_workers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// availabilities_import => /availabilities/import/:id(.:format)
  // function(id, options)
  availabilities_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// availabilities_import_create => /availabilities/import_create(.:format)
  // function(options)
  availabilities_import_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[6,"import_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// availabilities_import_index => /availabilities/import(.:format)
  // function(options)
  availabilities_import_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// avatar => /avatars/:id(.:format)
  // function(id, options)
  avatar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"avatars",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// avatars => /avatars(.:format)
  // function(options)
  avatars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"avatars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// booking_acuity_score_create => /booking_acuity_score_create(.:format)
  // function(options)
  booking_acuity_score_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_acuity_score_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// booking_acuity_score_update => /booking_acuity_score_update(.:format)
  // function(options)
  booking_acuity_score_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_acuity_score_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bookings_bulk_update_dna => /bookings/bulk_update_dna(.:format)
  // function(options)
  bookings_bulk_update_dna_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"bulk_update_dna",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_entries => /bulk_entries(.:format)
  // function(options)
  bulk_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bulk_entry => /bulk_entries/:id(.:format)
  // function(id, options)
  bulk_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_update => /bulk_updates/:id(.:format)
  // function(id, options)
  bulk_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_updates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_updates => /bulk_updates(.:format)
  // function(options)
  bulk_updates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_updates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// call_monitoring_setting => /call_monitoring_settings/:id(.:format)
  // function(id, options)
  call_monitoring_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// call_monitoring_settings => /call_monitoring_settings(.:format)
  // function(options)
  call_monitoring_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cancel_form => /cancel_form(.:format)
  // function(options)
  cancel_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cancel_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cancellation => /cancel(.:format)
  // function(options)
  cancellation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cancellation_reason => /cancellation_reasons/:id(.:format)
  // function(id, options)
  cancellation_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cancellation_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancellation_reasons => /cancellation_reasons(.:format)
  // function(options)
  cancellation_reasons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cancellation_reasons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// check_availability => /check_availability(.:format)
  // function(options)
  check_availability_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"check_availability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// check_item_name_exists => /check_item_name_exists(.:format)
  // function(options)
  check_item_name_exists_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"check_item_name_exists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// check_nhs_number => /clients/check_nhs_number(.:format)
  // function(options)
  check_nhs_number_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"check_nhs_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_postcode => /check_postcode(.:format)
  // function(options)
  check_postcode_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"check_postcode",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// classification => /classifications/:id(.:format)
  // function(id, options)
  classification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// classifications => /classifications(.:format)
  // function(options)
  classifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"classifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// client => /c/:id(.:format)
  // function(id, options)
  client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// client_archived_reason => /client_archived_reasons/:id(.:format)
  // function(id, options)
  client_archived_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"client_archived_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// client_archived_reasons => /client_archived_reasons(.:format)
  // function(options)
  client_archived_reasons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client_archived_reasons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// client_check_incompatibilities => /c/:client_id/check_incompatibilities(.:format)
  // function(client_id, options)
  client_check_incompatibilities_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"check_incompatibilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_collections => /reports/client_collections(.:format)
  // function(options)
  client_collections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"client_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// client_event => /c/:client_id/e/:id(.:format)
  // function(client_id, id, options)
  client_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_events => /c/:client_id/e(.:format)
  // function(client_id, options)
  client_events_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"e",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_incompatibilities => /c/:client_id/incompatibilities(.:format)
  // function(client_id, options)
  client_incompatibilities_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_incompatibility => /c/:client_id/incompatibilities/:id(.:format)
  // function(client_id, id, options)
  client_incompatibility_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_incompatibility_expire => /c/:client_id/incompatibilities/:incompatibility_id/expire(.:format)
  // function(client_id, incompatibility_id, options)
  client_incompatibility_expire_path: Utils.route([["client_id",true],["incompatibility_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[3,"incompatibility_id",false],[2,[7,"/",false],[2,[6,"expire",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_jobsheet_collect_clients => /collect_clients(.:format)
  // function(options)
  client_jobsheet_collect_clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collect_clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// client_jobsheet_download => /client/jobsheets/download(.:format)
  // function(options)
  client_jobsheet_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client",false],[2,[7,"/",false],[2,[6,"jobsheets",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_jobsheets => /client/jobsheets(.:format)
  // function(options)
  client_jobsheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client",false],[2,[7,"/",false],[2,[6,"jobsheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// client_mars_sheet_download => /client/mars_sheets/download(.:format)
  // function(options)
  client_mars_sheet_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client",false],[2,[7,"/",false],[2,[6,"mars_sheets",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_mars_sheets => /client/mars_sheets(.:format)
  // function(options)
  client_mars_sheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client",false],[2,[7,"/",false],[2,[6,"mars_sheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// client_medication => /c/:client_id/medications/:id(.:format)
  // function(client_id, id, options)
  client_medication_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_medication_expire => /c/:client_id/medications/:medication_id/expire(.:format)
  // function(client_id, medication_id, options)
  client_medication_expire_path: Utils.route([["client_id",true],["medication_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medications",false],[2,[7,"/",false],[2,[3,"medication_id",false],[2,[7,"/",false],[2,[6,"expire",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_medication_expire_form => /c/:client_id/medications/:medication_id/expire_form(.:format)
  // function(client_id, medication_id, options)
  client_medication_expire_form_path: Utils.route([["client_id",true],["medication_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medications",false],[2,[7,"/",false],[2,[3,"medication_id",false],[2,[7,"/",false],[2,[6,"expire_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_medication_notes => /c/:client_id/medication_notes(.:format)
  // function(client_id, options)
  client_medication_notes_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medication_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_medications => /c/:client_id/medications(.:format)
  // function(client_id, options)
  client_medications_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_message => /c/:client_id/messages/:id(.:format)
  // function(client_id, id, options)
  client_message_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_messages => /c/:client_id/messages(.:format)
  // function(client_id, options)
  client_messages_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_repeating_event => /c/:client_id/re/:id(.:format)
  // function(client_id, id, options)
  client_repeating_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_repeating_events => /c/:client_id/re(.:format)
  // function(client_id, options)
  client_repeating_events_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"re",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_set_site_fields => /client_set_site_fields(.:format)
  // function(options)
  client_set_site_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client_set_site_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// client_site => /c/:client_id/s/:id(.:format)
  // function(client_id, id, options)
  client_site_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_site_event => /c/:client_id/s/:site_id/e/:id(.:format)
  // function(client_id, site_id, id, options)
  client_site_event_path: Utils.route([["client_id",true],["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// client_site_event_collection => /c/:client_id/s/:site_id/e/:event_id/collections/:id(.:format)
  // function(client_id, site_id, event_id, id, options)
  client_site_event_collection_path: Utils.route([["client_id",true],["site_id",true],["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// client_site_event_collections => /c/:client_id/s/:site_id/e/:event_id/collections(.:format)
  // function(client_id, site_id, event_id, options)
  client_site_event_collections_path: Utils.route([["client_id",true],["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// client_site_event_edit_response => /c/:client_id/s/:site_id/e/:event_id/edit_response(.:format)
  // function(client_id, site_id, event_id, options)
  client_site_event_edit_response_path: Utils.route([["client_id",true],["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"edit_response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// client_site_event_update_response => /c/:client_id/s/:site_id/e/:event_id/update_response(.:format)
  // function(client_id, site_id, event_id, options)
  client_site_event_update_response_path: Utils.route([["client_id",true],["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"update_response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// client_site_events => /c/:client_id/s/:site_id/e(.:format)
  // function(client_id, site_id, options)
  client_site_events_path: Utils.route([["client_id",true],["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_site_export => /c/:client_id/s/:site_id/export(.:format)
  // function(client_id, site_id, options)
  client_site_export_path: Utils.route([["client_id",true],["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_site_import => /c/:client_id/site_imports/:id(.:format)
  // function(client_id, id, options)
  client_site_import_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"site_imports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_site_import_process => /c/:client_id/site_imports/:site_import_id/process(.:format)
  // function(client_id, site_import_id, options)
  client_site_import_process_path: Utils.route([["client_id",true],["site_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"site_imports",false],[2,[7,"/",false],[2,[3,"site_import_id",false],[2,[7,"/",false],[2,[6,"process",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_site_imports => /c/:client_id/site_imports(.:format)
  // function(client_id, options)
  client_site_imports_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"site_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_site_repeating_event => /c/:client_id/s/:site_id/re/:id(.:format)
  // function(client_id, site_id, id, options)
  client_site_repeating_event_path: Utils.route([["client_id",true],["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// client_site_repeating_events => /c/:client_id/s/:site_id/re(.:format)
  // function(client_id, site_id, options)
  client_site_repeating_events_path: Utils.route([["client_id",true],["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// client_sites => /c/:client_id/s(.:format)
  // function(client_id, options)
  client_sites_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clients => /c(.:format)
  // function(options)
  clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// clients_archive => /clients/:id/archive(.:format)
  // function(id, options)
  clients_archive_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clients_import => /clients/import/:id(.:format)
  // function(id, options)
  clients_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clients_import_failed_csv => /clients/import_failed_csv(.:format)
  // function(options)
  clients_import_failed_csv_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"import_failed_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clients_import_index => /clients/import(.:format)
  // function(options)
  clients_import_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clients_trait => /clients_traits/:id(.:format)
  // function(id, options)
  clients_trait_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients_traits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clients_traits => /clients_traits(.:format)
  // function(options)
  clients_traits_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients_traits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// clients_traits_destroy => /clients_traits_destroy(.:format)
  // function(options)
  clients_traits_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients_traits_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// clients_unarchive => /clients/:id/unarchive(.:format)
  // function(id, options)
  clients_unarchive_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clients_week_view => /reports/clients/week(.:format)
  // function(options)
  clients_week_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"week",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// collect_events => /collect_events(.:format)
  // function(options)
  collect_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collect_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// collect_sites => /collect_sites(.:format)
  // function(options)
  collect_sites_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collect_sites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// collection => /collections/:id(.:format)
  // function(id, options)
  collection_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// collections => /collections(.:format)
  // function(options)
  collections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// comment => /comments/:id(.:format)
  // function(id, options)
  comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comments => /comments(.:format)
  // function(options)
  comments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// configure => /configure(.:format)
  // function(options)
  configure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"configure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// consultant_code => /consultant_codes/:id(.:format)
  // function(id, options)
  consultant_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"consultant_codes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// consultant_codes => /consultant_codes(.:format)
  // function(options)
  consultant_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"consultant_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// course => /courses/:id(.:format)
  // function(id, options)
  course_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// course_archive => /courses/:course_id/archive(.:format)
  // function(course_id, options)
  course_archive_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// course_unarchive => /courses/:course_id/unarchive(.:format)
  // function(course_id, options)
  course_unarchive_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// courses => /courses(.:format)
  // function(options)
  courses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// create_csds_report => /create_csds_report(.:format)
  // function(options)
  create_csds_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"create_csds_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// current_user_disable_2fa => /current_user_disable_2fa(.:format)
  // function(options)
  current_user_disable_2fa_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"current_user_disable_2fa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// current_user_enable_2fa => /current_user_enable_2fa(.:format)
  // function(options)
  current_user_enable_2fa_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"current_user_enable_2fa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// current_user_messages => /current_user_messages(.:format)
  // function(options)
  current_user_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"current_user_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// current_user_settings => /current_user_settings(.:format)
  // function(options)
  current_user_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"current_user_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// custom_eden_reports_leave_create => /custom/eden/reports/leave/create(.:format)
  // function(options)
  custom_eden_reports_leave_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[6,"eden",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// custom_eden_reports_leave_new => /custom/eden/reports/leave/new(.:format)
  // function(options)
  custom_eden_reports_leave_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[6,"eden",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// custom_field_results_import => /custom_field_results/import/:id(.:format)
  // function(id, options)
  custom_field_results_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_field_results",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_field_results_import_index => /custom_field_results/import(.:format)
  // function(options)
  custom_field_results_import_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_field_results",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// custom_ics_mail_merge_initial => /custom/ics/mail_merge/initial(.:format)
  // function(options)
  custom_ics_mail_merge_initial_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[6,"ics",false],[2,[7,"/",false],[2,[6,"mail_merge",false],[2,[7,"/",false],[2,[6,"initial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// custom_ics_mail_merge_sessions => /custom/ics/mail_merge/sessions(.:format)
  // function(options)
  custom_ics_mail_merge_sessions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[6,"ics",false],[2,[7,"/",false],[2,[6,"mail_merge",false],[2,[7,"/",false],[2,[6,"sessions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// custom_lakeshore_copy_week => /custom/lakeshore/copy_week(.:format)
  // function(options)
  custom_lakeshore_copy_week_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[6,"lakeshore",false],[2,[7,"/",false],[2,[6,"copy_week",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_lakeshore_copy_week_index => /custom/lakeshore/copy_week_index(.:format)
  // function(options)
  custom_lakeshore_copy_week_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[6,"lakeshore",false],[2,[7,"/",false],[2,[6,"copy_week_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_report => /custom_reports/:id(.:format)
  // function(id, options)
  custom_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// custom_report_download => /custom_reports/:id/download(.:format)
  // function(id, options)
  custom_report_download_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_reports => /custom_reports(.:format)
  // function(options)
  custom_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboard => /dashboard(.:format)
  // function(options)
  dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboard_demand_capacity => /capacity(.:format)
  // function(options)
  dashboard_demand_capacity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"capacity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboard_react_demand_capacity => /react_capacity(.:format)
  // function(options)
  dashboard_react_demand_capacity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"react_capacity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboard_weekly_planner => /weekly_planner(.:format)
  // function(options)
  dashboard_weekly_planner_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"weekly_planner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// day => /day/:object_class(.:format)
  // function(object_class, options)
  day_path: Utils.route([["object_class",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"day",false],[2,[7,"/",false],[2,[3,"object_class",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// day_cell => /day_cell(.:format)
  // function(options)
  day_cell_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"day_cell",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// day_download => /day_download(.:format)
  // function(options)
  day_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"day_download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// day_row => /day_row(.:format)
  // function(options)
  day_row_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"day_row",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// did_not_attend_reason => /did_not_attend_reasons/:id(.:format)
  // function(id, options)
  did_not_attend_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"did_not_attend_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// did_not_attend_reasons => /did_not_attend_reasons(.:format)
  // function(options)
  did_not_attend_reasons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"did_not_attend_reasons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// disable_alert => /disable(.:format)
  // function(options)
  disable_alert_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"disable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// drag_and_drop_events_move => /drag_and_drop_events_move(.:format)
  // function(options)
  drag_and_drop_events_move_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"drag_and_drop_events_move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// drag_and_drop_users_allocate => /drag_and_drop_users_allocate(.:format)
  // function(options)
  drag_and_drop_users_allocate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"drag_and_drop_users_allocate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// drag_and_drop_users_collect => /drag_and_drop_users_collect(.:format)
  // function(options)
  drag_and_drop_users_collect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"drag_and_drop_users_collect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// drag_and_drop_users_unallocate => /drag_and_drop_users_unallocate(.:format)
  // function(options)
  drag_and_drop_users_unallocate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"drag_and_drop_users_unallocate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// earliest_time_win_from_items => /earliest_time_win_from_items(.:format)
  // function(options)
  earliest_time_win_from_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"earliest_time_win_from_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_account => /accounts/:id/edit(.:format)
  // function(id, options)
  edit_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account_account_file => /accounts/:account_id/account_files/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_account_file_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_activity => /activities/:id/edit(.:format)
  // function(id, options)
  edit_activity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admin_account => /admin/accounts/:id/edit(.:format)
  // function(id, options)
  edit_admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_account_account_file => /admin/accounts/:account_id/account_files/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_account_file_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_acuity_score => /admin/accounts/:account_id/acuity_scores/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_acuity_score_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"acuity_scores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_alert_config => /admin/accounts/:account_id/alert_configs/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_alert_config_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"alert_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_autoscheduling_setting => /admin/accounts/:account_id/autoscheduling_settings/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_autoscheduling_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"autoscheduling_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_call_monitoring_setting => /admin/accounts/:account_id/call_monitoring_settings/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_call_monitoring_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_client => /admin/accounts/:account_id/clients/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_client_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_custom_field => /admin/accounts/:account_id/custom_fields/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_custom_field_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_custom_field_custom_field_value => /admin/accounts/:account_id/custom_fields/:custom_field_id/custom_field_values/:id/edit(.:format)
  // function(account_id, custom_field_id, id, options)
  edit_admin_account_custom_field_custom_field_value_path: Utils.route([["account_id",true],["custom_field_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"custom_field_id",false],[2,[7,"/",false],[2,[6,"custom_field_values",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_admin_account_import => /admin/accounts/:account_id/import/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_import_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_item => /admin/accounts/:account_id/items/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_item_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_message => /admin/accounts/:account_id/messages/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_message_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_mobile_app_setting => /admin/accounts/:account_id/mobile_app_settings/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_mobile_app_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"mobile_app_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_routing_setting => /admin/accounts/:account_id/routing_settings/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_routing_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"routing_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_setting => /admin/accounts/:account_id/settings/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_setting_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_taxonomy => /admin/accounts/:account_id/taxonomies/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_taxonomy_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"taxonomies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_textmarketer_config => /admin/accounts/:account_id/textmarketer/configs/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_textmarketer_config_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"textmarketer",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_admin_account_user => /admin/accounts/:account_id/users/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_user_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_account_zoho_import => /admin/accounts/:account_id/zoho_import/:id/edit(.:format)
  // function(account_id, id, options)
  edit_admin_account_zoho_import_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"zoho_import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_ccg => /admin/ccgs/:id/edit(.:format)
  // function(id, options)
  edit_admin_ccg_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ccgs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_malinko_term => /admin/malinko_terms/:id/edit(.:format)
  // function(id, options)
  edit_admin_malinko_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_mobile_app_build => /admin/mobile_app_builds/:id/edit(.:format)
  // function(id, options)
  edit_admin_mobile_app_build_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"mobile_app_builds",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_postcode => /admin/postcodes/:id/edit(.:format)
  // function(id, options)
  edit_admin_postcode_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"postcodes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_practice => /admin/practices/:id/edit(.:format)
  // function(id, options)
  edit_admin_practice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"practices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_term => /admin/malinko_terms/:id/edit(.:format)
  // function(id, options)
  edit_admin_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_user => /admin/user/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_rest_v1_client => /api/rest/v1/clients/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v1_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v1_client_event => /api/rest/v1/clients/:client_id/events/:id/edit(.:format)
  // function(client_id, id, options)
  edit_api_rest_v1_client_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_api_rest_v1_event => /api/rest/v1/events/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v1_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v1_item => /api/rest/v1/items/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v1_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v1_programme => /api/rest/v1/programmes/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v1_programme_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v1_response => /api/rest/v1/responses/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v1_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v1_user => /api/rest/v1/users/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v1_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v2_client => /api/rest/v2/clients/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v2_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v2_client_event => /api/rest/v2/clients/:client_id/events/:id/edit(.:format)
  // function(client_id, id, options)
  edit_api_rest_v2_client_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_api_rest_v2_event => /api/rest/v2/events/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v2_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v2_item => /api/rest/v2/items/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v2_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v2_programme => /api/rest/v2/programmes/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v2_programme_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v2_response => /api/rest/v2/responses/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v2_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_rest_v2_user => /api/rest/v2/users/:id/edit(.:format)
  // function(id, options)
  edit_api_rest_v2_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_attendance => /attendances/:id/edit(.:format)
  // function(id, options)
  edit_attendance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"attendances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_auto_schedule_worker => /auto_schedule_workers/:id/edit(.:format)
  // function(id, options)
  edit_auto_schedule_worker_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_schedule_workers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_availabilities_import => /availabilities/import/:id/edit(.:format)
  // function(id, options)
  edit_availabilities_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_avatar => /avatars/:id/edit(.:format)
  // function(id, options)
  edit_avatar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"avatars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_bulk_entry => /bulk_entries/:id/edit(.:format)
  // function(id, options)
  edit_bulk_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_bulk_update => /bulk_updates/:id/edit(.:format)
  // function(id, options)
  edit_bulk_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_updates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_call_monitoring_setting => /call_monitoring_settings/:id/edit(.:format)
  // function(id, options)
  edit_call_monitoring_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_cancellation_reason => /cancellation_reasons/:id/edit(.:format)
  // function(id, options)
  edit_cancellation_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cancellation_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_client => /c/:id/edit(.:format)
  // function(id, options)
  edit_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_client_archived_reason => /client_archived_reasons/:id/edit(.:format)
  // function(id, options)
  edit_client_archived_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"client_archived_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_client_event => /c/:client_id/e/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_incompatibility => /c/:client_id/incompatibilities/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_incompatibility_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_medication => /c/:client_id/medications/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_medication_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_message => /c/:client_id/messages/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_message_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_repeating_event => /c/:client_id/re/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_repeating_event_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_site => /c/:client_id/s/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_site_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_site_event => /c/:client_id/s/:site_id/e/:id/edit(.:format)
  // function(client_id, site_id, id, options)
  edit_client_site_event_path: Utils.route([["client_id",true],["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_client_site_event_collection => /c/:client_id/s/:site_id/e/:event_id/collections/:id/edit(.:format)
  // function(client_id, site_id, event_id, id, options)
  edit_client_site_event_collection_path: Utils.route([["client_id",true],["site_id",true],["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
// edit_client_site_import => /c/:client_id/site_imports/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_site_import_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"site_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_site_repeating_event => /c/:client_id/s/:site_id/re/:id/edit(.:format)
  // function(client_id, site_id, id, options)
  edit_client_site_repeating_event_path: Utils.route([["client_id",true],["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_clients_import => /clients/import/:id/edit(.:format)
  // function(id, options)
  edit_clients_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_clients_trait => /clients_traits/:id/edit(.:format)
  // function(id, options)
  edit_clients_trait_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients_traits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_collection => /collections/:id/edit(.:format)
  // function(id, options)
  edit_collection_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_comment => /comments/:id/edit(.:format)
  // function(id, options)
  edit_comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_consultant_code => /consultant_codes/:id/edit(.:format)
  // function(id, options)
  edit_consultant_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"consultant_codes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_course => /courses/:id/edit(.:format)
  // function(id, options)
  edit_course_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_custom_field_results_import => /custom_field_results/import/:id/edit(.:format)
  // function(id, options)
  edit_custom_field_results_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_field_results",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_custom_report => /custom_reports/:id/edit(.:format)
  // function(id, options)
  edit_custom_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_did_not_attend_reason => /did_not_attend_reasons/:id/edit(.:format)
  // function(id, options)
  edit_did_not_attend_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"did_not_attend_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_event => /e/:id/edit(.:format)
  // function(id, options)
  edit_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_event_booking => /e/:event_id/bookings/:id/edit(.:format)
  // function(event_id, id, options)
  edit_event_booking_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_event_collection_item => /e/:event_id/collection_items/:id/edit(.:format)
  // function(event_id, id, options)
  edit_event_collection_item_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collection_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_event_message => /e/:event_id/messages/:id/edit(.:format)
  // function(event_id, id, options)
  edit_event_message_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_event_movement_reason => /event_movement_reasons/:id/edit(.:format)
  // function(id, options)
  edit_event_movement_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"event_movement_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_event_response => /e/:event_id/responses/:id/edit(.:format)
  // function(event_id, id, options)
  edit_event_response_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_events_group => /events/groups/:id/edit(.:format)
  // function(id, options)
  edit_events_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_events_import => /events/import/:id/edit(.:format)
  // function(id, options)
  edit_events_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_events_load => /events/load/:id/edit(.:format)
  // function(id, options)
  edit_events_load_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"load",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_group => /groups/:id/edit(.:format)
  // function(id, options)
  edit_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_item => /i/:id/edit(.:format)
  // function(id, options)
  edit_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_list_import => /list_imports/:id/edit(.:format)
  // function(id, options)
  edit_list_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"list_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_medication_type => /medication_types/:id/edit(.:format)
  // function(id, options)
  edit_medication_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"medication_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_message => /messages/:id/edit(.:format)
  // function(id, options)
  edit_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_message_type => /message_types/:id/edit(.:format)
  // function(id, options)
  edit_message_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"message_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_mobile_app_build => /mobile_app_builds/:id/edit(.:format)
  // function(id, options)
  edit_mobile_app_build_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app_builds",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_notification => /notifications/:id/edit(.:format)
  // function(id, options)
  edit_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_oauth_application => /oauth/applications/:id/edit(.:format)
  // function(id, options)
  edit_oauth_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_programme => /programmes/:id/edit(.:format)
  // function(id, options)
  edit_programme_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_programme_programme_client => /programmes/:programme_id/programme_clients/:id/edit(.:format)
  // function(programme_id, id, options)
  edit_programme_programme_client_path: Utils.route([["programme_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_programme_programme_event => /programmes/:programme_id/programme_events/:id/edit(.:format)
  // function(programme_id, id, options)
  edit_programme_programme_event_path: Utils.route([["programme_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_repeating_event => /re/:id/edit(.:format)
  // function(id, options)
  edit_repeating_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_repeating_event_allocation => /re/:repeating_event_id/allocations/:id/edit(.:format)
  // function(repeating_event_id, id, options)
  edit_repeating_event_allocation_path: Utils.route([["repeating_event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"allocations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_repeating_event_message => /re/:repeating_event_id/messages/:id/edit(.:format)
  // function(repeating_event_id, id, options)
  edit_repeating_event_message_path: Utils.route([["repeating_event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_repeating_events_date_from_interval => /repeating_events/date_from_interval/:id/edit(.:format)
  // function(id, options)
  edit_repeating_events_date_from_interval_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"date_from_interval",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_repeating_events_events_to_delete => /repeating_events/events_to_delete/:id/edit(.:format)
  // function(id, options)
  edit_repeating_events_events_to_delete_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"events_to_delete",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_repeating_events_group => /repeating_events/groups/:id/edit(.:format)
  // function(id, options)
  edit_repeating_events_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_reports_activity => /reports/activities/:id/edit(.:format)
  // function(id, options)
  edit_reports_activity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_reports_item => /reports/items/:id/edit(.:format)
  // function(id, options)
  edit_reports_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_reports_user_event => /reports/user_events/:id/edit(.:format)
  // function(id, options)
  edit_reports_user_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_responses_import => /responses/import/:id/edit(.:format)
  // function(id, options)
  edit_responses_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_routing_worker => /routing_workers/:id/edit(.:format)
  // function(id, options)
  edit_routing_worker_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"routing_workers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_score_multiplier => /score_multipliers/:id/edit(.:format)
  // function(id, options)
  edit_score_multiplier_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"score_multipliers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_search => /search/:id/edit(.:format)
  // function(id, options)
  edit_search_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_selenity_config => /selenity_configs/:id/edit(.:format)
  // function(id, options)
  edit_selenity_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"selenity_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_setting => /settings/:id/edit(.:format)
  // function(id, options)
  edit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_site => /s/:id/edit(.:format)
  // function(id, options)
  edit_site_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_site_event => /s/:site_id/e/:id/edit(.:format)
  // function(site_id, id, options)
  edit_site_event_path: Utils.route([["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_site_event_collection => /s/:site_id/e/:event_id/collections/:id/edit(.:format)
  // function(site_id, event_id, id, options)
  edit_site_event_collection_path: Utils.route([["site_id",true],["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_site_message => /s/:site_id/messages/:id/edit(.:format)
  // function(site_id, id, options)
  edit_site_message_path: Utils.route([["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_site_repeating_event => /s/:site_id/re/:id/edit(.:format)
  // function(site_id, id, options)
  edit_site_repeating_event_path: Utils.route([["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_sites_planner => /sites/planner/:id/edit(.:format)
  // function(id, options)
  edit_sites_planner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"planner",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sites_week => /sites/week/:id/edit(.:format)
  // function(id, options)
  edit_sites_week_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"week",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sms_message => /sms_messages/:id/edit(.:format)
  // function(id, options)
  edit_sms_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sms_template => /sms_templates/:id/edit(.:format)
  // function(id, options)
  edit_sms_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_training => /trainings/:id/edit(.:format)
  // function(id, options)
  edit_training_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_trait => /traits/:id/edit(.:format)
  // function(id, options)
  edit_trait_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"traits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_traits_user => /traits_users/:id/edit(.:format)
  // function(id, options)
  edit_traits_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"traits_users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_availability => /users/:user_id/availabilities/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_availability_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_default_availability => /users/:user_id/default_availabilities/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_default_availability_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"default_availabilities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_event => /users/:user_id/e/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_event_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_incompatibility => /users/:user_id/incompatibilities/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_incompatibility_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_message => /users/:user_id/messages/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_message_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_single_availability => /users/:user_id/single_availabilities/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_single_availability_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"single_availabilities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_users_collect => /users/collect/:id/edit(.:format)
  // function(id, options)
  edit_users_collect_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"collect",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_users_compact_week => /users/compact_week/:id/edit(.:format)
  // function(id, options)
  edit_users_compact_week_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// enterprise_day_view => /reports/users/enterprise_day(.:format)
  // function(options)
  enterprise_day_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"enterprise_day",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// european_waste_code => /european_waste_code/search(.:format)
  // function(options)
  european_waste_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"european_waste_code",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// event => /e/:id(.:format)
  // function(id, options)
  event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// event_booking => /e/:event_id/bookings/:id(.:format)
  // function(event_id, id, options)
  event_booking_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// event_bookings => /e/:event_id/bookings(.:format)
  // function(event_id, options)
  event_bookings_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"bookings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// event_collection_item => /e/:event_id/collection_items/:id(.:format)
  // function(event_id, id, options)
  event_collection_item_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collection_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// event_collection_items => /e/:event_id/collection_items(.:format)
  // function(event_id, options)
  event_collection_items_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collection_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// event_message => /e/:event_id/messages/:id(.:format)
  // function(event_id, id, options)
  event_message_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// event_messages => /e/:event_id/messages(.:format)
  // function(event_id, options)
  event_messages_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// event_movement_reason => /event_movement_reasons/:id(.:format)
  // function(id, options)
  event_movement_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"event_movement_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// event_movement_reasons => /event_movement_reasons(.:format)
  // function(options)
  event_movement_reasons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"event_movement_reasons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// event_response => /e/:event_id/responses/:id(.:format)
  // function(event_id, id, options)
  event_response_path: Utils.route([["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// event_responses => /e/:event_id/responses(.:format)
  // function(event_id, options)
  event_responses_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// event_suggest_group => /events/suggest_group(.:format)
  // function(options)
  event_suggest_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"suggest_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// event_survey_download => /e/:event_id/surveys/download(.:format)
  // function(event_id, options)
  event_survey_download_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"surveys",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// events => /e(.:format)
  // function(options)
  events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// events_bulk_actions => /events/bulk_actions(.:format)
  // function(options)
  events_bulk_actions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"bulk_actions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_for_object_date_and_period => /events_for_object_date_and_period(.:format)
  // function(options)
  events_for_object_date_and_period_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events_for_object_date_and_period",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// events_group => /events/groups/:id(.:format)
  // function(id, options)
  events_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// events_group_update => /events/group_update(.:format)
  // function(options)
  events_group_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"group_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_groups => /events/groups(.:format)
  // function(options)
  events_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_import => /events/import/:id(.:format)
  // function(id, options)
  events_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// events_import_index => /events/import(.:format)
  // function(options)
  events_import_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_load => /events/load/:id(.:format)
  // function(id, options)
  events_load_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"load",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// events_load_index => /events/load(.:format)
  // function(options)
  events_load_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"load",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// events_move => /events/move(.:format)
  // function(options)
  events_move_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_failed_import_csv => /admin/accounts/:account_id/import/:list_id/download_failure.csv(.:format)
  // function(account_id, list_id, options)
  generate_failed_import_csv_path: Utils.route([["account_id",true],["list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"list_id",false],[2,[7,"/",false],[2,[6,"download_failure",false],[2,[8,".",false],[2,[6,"csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// get_attendances => /get_attendances(.:format)
  // function(options)
  get_attendances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"get_attendances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// group => /groups/:id(.:format)
  // function(id, options)
  group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// group_add_member_field => /group_add_member_field(.:format)
  // function(options)
  group_add_member_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"group_add_member_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// group_add_members => /groups/:group_id/add_members(.:format)
  // function(group_id, options)
  group_add_members_path: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"add_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// groups => /groups(.:format)
  // function(options)
  groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// groups_switch => /groups/switch(.:format)
  // function(options)
  groups_switch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"switch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// healthroster_configurations => /healthroster_configurations(.:format)
  // function(options)
  healthroster_configurations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"healthroster_configurations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// heartbeat => /heartbeat(.:format)
  // function(options)
  heartbeat_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"heartbeat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// import => /admin/accounts/:account_id/import/:list_id/process(.:format)
  // function(account_id, list_id, options)
  import_path: Utils.route([["account_id",true],["list_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"list_id",false],[2,[7,"/",false],[2,[6,"process",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// item => /i/:id(.:format)
  // function(id, options)
  item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item_export => /i/:item_id/export(.:format)
  // function(item_id, options)
  item_export_path: Utils.route([["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// items => /i(.:format)
  // function(options)
  items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// items_check_default_duration => /items_check_default_duration(.:format)
  // function(options)
  items_check_default_duration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"items_check_default_duration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// items_check_time_window => /items_check_time_window(.:format)
  // function(options)
  items_check_time_window_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"items_check_time_window",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// leave => /users/:user_id/leave(.:format)
  // function(user_id, options)
  leave_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"leave",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// leave_planner => /leave_planner(.:format)
  // function(options)
  leave_planner_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_planner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// list_import => /list_imports/:id(.:format)
  // function(id, options)
  list_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"list_imports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// list_imports => /list_imports(.:format)
  // function(options)
  list_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"list_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// live_status_edit_time_fields => /live_status/edit_time_fields(.:format)
  // function(options)
  live_status_edit_time_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"live_status",false],[2,[7,"/",false],[2,[6,"edit_time_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// medication_type => /medication_types/:id(.:format)
  // function(id, options)
  medication_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"medication_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// medication_types => /medication_types(.:format)
  // function(options)
  medication_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"medication_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// message => /messages/:id(.:format)
  // function(id, options)
  message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// message_type => /message_types/:id(.:format)
  // function(id, options)
  message_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"message_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// message_type_archive => /message_types/:message_type_id/archive(.:format)
  // function(message_type_id, options)
  message_type_archive_path: Utils.route([["message_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"message_types",false],[2,[7,"/",false],[2,[3,"message_type_id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// message_type_unarchive => /message_types/:message_type_id/unarchive(.:format)
  // function(message_type_id, options)
  message_type_unarchive_path: Utils.route([["message_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"message_types",false],[2,[7,"/",false],[2,[3,"message_type_id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// message_types => /message_types(.:format)
  // function(options)
  message_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"message_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// messages => /messages(.:format)
  // function(options)
  messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// mobile_app_build => /mobile_app_builds/:id(.:format)
  // function(id, options)
  mobile_app_build_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app_builds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mobile_app_builds => /mobile_app_builds(.:format)
  // function(options)
  mobile_app_builds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app_builds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// modify_trait => /traits/:id/modify(.:format)
  // function(id, options)
  modify_trait_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"traits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"modify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// more_reports => /reports/more(.:format)
  // function(options)
  more_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"more",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// move_event => /events/move(.:format)
  // function(options)
  move_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// native_oauth_authorization => /oauth/authorize/native(.:format)
  // function(options)
  native_oauth_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[6,"native",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_account => /accounts/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account_account_file => /accounts/:account_id/account_files/new(.:format)
  // function(account_id, options)
  new_account_account_file_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_activity => /activities/new(.:format)
  // function(options)
  new_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_account => /admin/accounts/new(.:format)
  // function(options)
  new_admin_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_account_account_file => /admin/accounts/:account_id/account_files/new(.:format)
  // function(account_id, options)
  new_admin_account_account_file_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"account_files",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_acuity_score => /admin/accounts/:account_id/acuity_scores/new(.:format)
  // function(account_id, options)
  new_admin_account_acuity_score_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"acuity_scores",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_alert_config => /admin/accounts/:account_id/alert_configs/new(.:format)
  // function(account_id, options)
  new_admin_account_alert_config_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"alert_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_autoscheduling_setting => /admin/accounts/:account_id/autoscheduling_settings/new(.:format)
  // function(account_id, options)
  new_admin_account_autoscheduling_setting_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"autoscheduling_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_call_monitoring_setting => /admin/accounts/:account_id/call_monitoring_settings/new(.:format)
  // function(account_id, options)
  new_admin_account_call_monitoring_setting_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_client => /admin/accounts/:account_id/clients/new(.:format)
  // function(account_id, options)
  new_admin_account_client_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_custom_field => /admin/accounts/:account_id/custom_fields/new(.:format)
  // function(account_id, options)
  new_admin_account_custom_field_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_custom_field_custom_field_value => /admin/accounts/:account_id/custom_fields/:custom_field_id/custom_field_values/new(.:format)
  // function(account_id, custom_field_id, options)
  new_admin_account_custom_field_custom_field_value_path: Utils.route([["account_id",true],["custom_field_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"custom_field_id",false],[2,[7,"/",false],[2,[6,"custom_field_values",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_admin_account_import => /admin/accounts/:account_id/import/new(.:format)
  // function(account_id, options)
  new_admin_account_import_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_item => /admin/accounts/:account_id/items/new(.:format)
  // function(account_id, options)
  new_admin_account_item_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_message => /admin/accounts/:account_id/messages/new(.:format)
  // function(account_id, options)
  new_admin_account_message_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_mobile_app_setting => /admin/accounts/:account_id/mobile_app_settings/new(.:format)
  // function(account_id, options)
  new_admin_account_mobile_app_setting_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"mobile_app_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_routing_setting => /admin/accounts/:account_id/routing_settings/new(.:format)
  // function(account_id, options)
  new_admin_account_routing_setting_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"routing_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_setting => /admin/accounts/:account_id/settings/new(.:format)
  // function(account_id, options)
  new_admin_account_setting_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_taxonomy => /admin/accounts/:account_id/taxonomies/new(.:format)
  // function(account_id, options)
  new_admin_account_taxonomy_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"taxonomies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_textmarketer_config => /admin/accounts/:account_id/textmarketer/configs/new(.:format)
  // function(account_id, options)
  new_admin_account_textmarketer_config_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"textmarketer",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_admin_account_user => /admin/accounts/:account_id/users/new(.:format)
  // function(account_id, options)
  new_admin_account_user_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_account_zoho_import => /admin/accounts/:account_id/zoho_import/new(.:format)
  // function(account_id, options)
  new_admin_account_zoho_import_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"zoho_import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_ccg => /admin/ccgs/new(.:format)
  // function(options)
  new_admin_ccg_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ccgs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_malinko_term => /admin/malinko_terms/new(.:format)
  // function(options)
  new_admin_malinko_term_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_mobile_app_build => /admin/mobile_app_builds/new(.:format)
  // function(options)
  new_admin_mobile_app_build_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"mobile_app_builds",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_postcode => /admin/postcodes/new(.:format)
  // function(options)
  new_admin_postcode_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"postcodes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_practice => /admin/practices/new(.:format)
  // function(options)
  new_admin_practice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"practices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_term => /admin/malinko_terms/new(.:format)
  // function(options)
  new_admin_term_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"malinko_terms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user => /admin/user/new(.:format)
  // function(options)
  new_admin_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_rest_v1_client => /api/rest/v1/clients/new(.:format)
  // function(options)
  new_api_rest_v1_client_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v1_client_event => /api/rest/v1/clients/:client_id/events/new(.:format)
  // function(client_id, options)
  new_api_rest_v1_client_event_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_api_rest_v1_event => /api/rest/v1/events/new(.:format)
  // function(options)
  new_api_rest_v1_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v1_item => /api/rest/v1/items/new(.:format)
  // function(options)
  new_api_rest_v1_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v1_programme => /api/rest/v1/programmes/new(.:format)
  // function(options)
  new_api_rest_v1_programme_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v1_response => /api/rest/v1/responses/new(.:format)
  // function(options)
  new_api_rest_v1_response_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v1_user => /api/rest/v1/users/new(.:format)
  // function(options)
  new_api_rest_v1_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v2_client => /api/rest/v2/clients/new(.:format)
  // function(options)
  new_api_rest_v2_client_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v2_client_event => /api/rest/v2/clients/:client_id/events/new(.:format)
  // function(client_id, options)
  new_api_rest_v2_client_event_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_api_rest_v2_event => /api/rest/v2/events/new(.:format)
  // function(options)
  new_api_rest_v2_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v2_item => /api/rest/v2/items/new(.:format)
  // function(options)
  new_api_rest_v2_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v2_programme => /api/rest/v2/programmes/new(.:format)
  // function(options)
  new_api_rest_v2_programme_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v2_response => /api/rest/v2/responses/new(.:format)
  // function(options)
  new_api_rest_v2_response_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_rest_v2_user => /api/rest/v2/users/new(.:format)
  // function(options)
  new_api_rest_v2_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rest",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_attendance => /attendances/new(.:format)
  // function(options)
  new_attendance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"attendances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_auto_schedule_worker => /auto_schedule_workers/new(.:format)
  // function(options)
  new_auto_schedule_worker_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auto_schedule_workers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_availabilities_import => /availabilities/import/new(.:format)
  // function(options)
  new_availabilities_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_avatar => /avatars/new(.:format)
  // function(options)
  new_avatar_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"avatars",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_bulk_entry => /bulk_entries/new(.:format)
  // function(options)
  new_bulk_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_bulk_update => /bulk_updates/new(.:format)
  // function(options)
  new_bulk_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bulk_updates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_call_monitoring_setting => /call_monitoring_settings/new(.:format)
  // function(options)
  new_call_monitoring_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"call_monitoring_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_cancellation_reason => /cancellation_reasons/new(.:format)
  // function(options)
  new_cancellation_reason_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cancellation_reasons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_client => /c/new(.:format)
  // function(options)
  new_client_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_client_event => /c/:client_id/e/new(.:format)
  // function(client_id, options)
  new_client_event_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_incompatibility => /c/:client_id/incompatibilities/new(.:format)
  // function(client_id, options)
  new_client_incompatibility_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_medication => /c/:client_id/medications/new(.:format)
  // function(client_id, options)
  new_client_medication_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"medications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_message => /c/:client_id/messages/new(.:format)
  // function(client_id, options)
  new_client_message_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_repeating_event => /c/:client_id/re/new(.:format)
  // function(client_id, options)
  new_client_repeating_event_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_site => /c/:client_id/s/new(.:format)
  // function(client_id, options)
  new_client_site_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_site_event => /c/:client_id/s/:site_id/e/new(.:format)
  // function(client_id, site_id, options)
  new_client_site_event_path: Utils.route([["client_id",true],["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_client_site_event_collection => /c/:client_id/s/:site_id/e/:event_id/collections/new(.:format)
  // function(client_id, site_id, event_id, options)
  new_client_site_event_collection_path: Utils.route([["client_id",true],["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// new_client_site_import => /c/:client_id/site_imports/new(.:format)
  // function(client_id, options)
  new_client_site_import_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"site_imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_site_repeating_event => /c/:client_id/s/:site_id/re/new(.:format)
  // function(client_id, site_id, options)
  new_client_site_repeating_event_path: Utils.route([["client_id",true],["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_clients_import => /clients/import/new(.:format)
  // function(options)
  new_clients_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_clients_trait => /clients_traits/new(.:format)
  // function(options)
  new_clients_trait_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients_traits",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_collection => /collections/new(.:format)
  // function(options)
  new_collection_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_comment => /comments/new(.:format)
  // function(options)
  new_comment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_consultant_code => /consultant_codes/new(.:format)
  // function(options)
  new_consultant_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"consultant_codes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_course => /courses/new(.:format)
  // function(options)
  new_course_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_custom_field_results_import => /custom_field_results/import/new(.:format)
  // function(options)
  new_custom_field_results_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_field_results",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_custom_report => /custom_reports/new(.:format)
  // function(options)
  new_custom_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_did_not_attend_reason => /did_not_attend_reasons/new(.:format)
  // function(options)
  new_did_not_attend_reason_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"did_not_attend_reasons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_event => /e/new(.:format)
  // function(options)
  new_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_event_booking => /e/:event_id/bookings/new(.:format)
  // function(event_id, options)
  new_event_booking_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_event_collection_item => /e/:event_id/collection_items/new(.:format)
  // function(event_id, options)
  new_event_collection_item_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collection_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_event_message => /e/:event_id/messages/new(.:format)
  // function(event_id, options)
  new_event_message_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_event_movement_reason => /event_movement_reasons/new(.:format)
  // function(options)
  new_event_movement_reason_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"event_movement_reasons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_event_response => /e/:event_id/responses/new(.:format)
  // function(event_id, options)
  new_event_response_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_events_group => /events/groups/new(.:format)
  // function(options)
  new_events_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_events_import => /events/import/new(.:format)
  // function(options)
  new_events_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_events_load => /events/load/new(.:format)
  // function(options)
  new_events_load_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"load",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_group => /groups/new(.:format)
  // function(options)
  new_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_item => /i/new(.:format)
  // function(options)
  new_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_list_import => /list_imports/new(.:format)
  // function(options)
  new_list_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"list_imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_medication_type => /medication_types/new(.:format)
  // function(options)
  new_medication_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"medication_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_message => /messages/new(.:format)
  // function(options)
  new_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_message_type => /message_types/new(.:format)
  // function(options)
  new_message_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"message_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_mobile_app_build => /mobile_app_builds/new(.:format)
  // function(options)
  new_mobile_app_build_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app_builds",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_notification => /notifications/new(.:format)
  // function(options)
  new_notification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_oauth_application => /oauth/applications/new(.:format)
  // function(options)
  new_oauth_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_programme => /programmes/new(.:format)
  // function(options)
  new_programme_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_programme_programme_client => /programmes/:programme_id/programme_clients/new(.:format)
  // function(programme_id, options)
  new_programme_programme_client_path: Utils.route([["programme_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_programme_programme_event => /programmes/:programme_id/programme_events/new(.:format)
  // function(programme_id, options)
  new_programme_programme_event_path: Utils.route([["programme_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_repeating_event => /re/new(.:format)
  // function(options)
  new_repeating_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_repeating_event_allocation => /re/:repeating_event_id/allocations/new(.:format)
  // function(repeating_event_id, options)
  new_repeating_event_allocation_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"allocations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_repeating_event_message => /re/:repeating_event_id/messages/new(.:format)
  // function(repeating_event_id, options)
  new_repeating_event_message_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_repeating_events_date_from_interval => /repeating_events/date_from_interval/new(.:format)
  // function(options)
  new_repeating_events_date_from_interval_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"date_from_interval",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_repeating_events_events_to_delete => /repeating_events/events_to_delete/new(.:format)
  // function(options)
  new_repeating_events_events_to_delete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"events_to_delete",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_repeating_events_group => /repeating_events/groups/new(.:format)
  // function(options)
  new_repeating_events_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_reports_activity => /reports/activities/new(.:format)
  // function(options)
  new_reports_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_reports_item => /reports/items/new(.:format)
  // function(options)
  new_reports_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_reports_user_event => /reports/user_events/new(.:format)
  // function(options)
  new_reports_user_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_responses_import => /responses/import/new(.:format)
  // function(options)
  new_responses_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_routing_worker => /routing_workers/new(.:format)
  // function(options)
  new_routing_worker_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"routing_workers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_score_multiplier => /score_multipliers/new(.:format)
  // function(options)
  new_score_multiplier_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"score_multipliers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_search => /search/new(.:format)
  // function(options)
  new_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_selenity_config => /selenity_configs/new(.:format)
  // function(options)
  new_selenity_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"selenity_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_setting => /settings/new(.:format)
  // function(options)
  new_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_site => /s/new(.:format)
  // function(options)
  new_site_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_site_event => /s/:site_id/e/new(.:format)
  // function(site_id, options)
  new_site_event_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_site_event_collection => /s/:site_id/e/:event_id/collections/new(.:format)
  // function(site_id, event_id, options)
  new_site_event_collection_path: Utils.route([["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_site_message => /s/:site_id/messages/new(.:format)
  // function(site_id, options)
  new_site_message_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_site_repeating_event => /s/:site_id/re/new(.:format)
  // function(site_id, options)
  new_site_repeating_event_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_sites_planner => /sites/planner/new(.:format)
  // function(options)
  new_sites_planner_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"planner",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sites_week => /sites/week/new(.:format)
  // function(options)
  new_sites_week_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"week",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sms_message => /sms_messages/new(.:format)
  // function(options)
  new_sms_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sms_template => /sms_templates/new(.:format)
  // function(options)
  new_sms_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_training => /trainings/new(.:format)
  // function(options)
  new_training_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_traits_user => /traits_users/new(.:format)
  // function(options)
  new_traits_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"traits_users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_availability => /users/:user_id/availabilities/new(.:format)
  // function(user_id, options)
  new_user_availability_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_default_availability => /users/:user_id/default_availabilities/new(.:format)
  // function(user_id, options)
  new_user_default_availability_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"default_availabilities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_event => /users/:user_id/e/new(.:format)
  // function(user_id, options)
  new_user_event_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_incompatibility => /users/:user_id/incompatibilities/new(.:format)
  // function(user_id, options)
  new_user_incompatibility_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_message => /users/:user_id/messages/new(.:format)
  // function(user_id, options)
  new_user_message_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_single_availability => /users/:user_id/single_availabilities/new(.:format)
  // function(user_id, options)
  new_user_single_availability_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"single_availabilities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_users_collect => /users/collect/new(.:format)
  // function(options)
  new_users_collect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"collect",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_users_compact_week => /users/compact_week/new(.:format)
  // function(options)
  new_users_compact_week_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// notification => /notifications/:id(.:format)
  // function(id, options)
  notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notifications => /notifications(.:format)
  // function(options)
  notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// oauth_application => /oauth/applications/:id(.:format)
  // function(id, options)
  oauth_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_applications => /oauth/applications(.:format)
  // function(options)
  oauth_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorization => /oauth/authorize(.:format)
  // function(options)
  oauth_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorized_application => /oauth/authorized_applications/:id(.:format)
  // function(id, options)
  oauth_authorized_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_authorized_applications => /oauth/authorized_applications(.:format)
  // function(options)
  oauth_authorized_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_introspect => /oauth/introspect(.:format)
  // function(options)
  oauth_introspect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"introspect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_revoke => /oauth/revoke(.:format)
  // function(options)
  oauth_revoke_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"revoke",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_token => /oauth/token(.:format)
  // function(options)
  oauth_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_token_info => /oauth/token/info(.:format)
  // function(options)
  oauth_token_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pages_client_discharge => /pages/client_discharge(.:format)
  // function(options)
  pages_client_discharge_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"client_discharge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_health_check => /pages/health_check(.:format)
  // function(options)
  pages_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"health_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_renumber_clients => /pages/renumber_clients(.:format)
  // function(options)
  pages_renumber_clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"renumber_clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_renumber_episodes => /pages/renumber_episodes(.:format)
  // function(options)
  pages_renumber_episodes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"renumber_episodes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_return_params => /pages/return_params(.:format)
  // function(options)
  pages_return_params_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"return_params",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// planned_maintenance => /planned_maintenance(.:format)
  // function(options)
  planned_maintenance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"planned_maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// print_messages => /print_messages(.:format)
  // function(options)
  print_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"print_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// programme => /programmes/:id(.:format)
  // function(id, options)
  programme_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// programme_cancelled => /programmes/:programme_id/cancelled(.:format)
  // function(programme_id, options)
  programme_cancelled_path: Utils.route([["programme_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"cancelled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// programme_programme_client => /programmes/:programme_id/programme_clients/:id(.:format)
  // function(programme_id, id, options)
  programme_programme_client_path: Utils.route([["programme_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// programme_programme_clients => /programmes/:programme_id/programme_clients(.:format)
  // function(programme_id, options)
  programme_programme_clients_path: Utils.route([["programme_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// programme_programme_event => /programmes/:programme_id/programme_events/:id(.:format)
  // function(programme_id, id, options)
  programme_programme_event_path: Utils.route([["programme_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// programme_programme_events => /programmes/:programme_id/programme_events(.:format)
  // function(programme_id, options)
  programme_programme_events_path: Utils.route([["programme_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[2,[7,"/",false],[2,[3,"programme_id",false],[2,[7,"/",false],[2,[6,"programme_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// programmes => /programmes(.:format)
  // function(options)
  programmes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"programmes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// quick_add_event => /quick_add_event(.:format)
  // function(options)
  quick_add_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"quick_add_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_incinerate => /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_incinerate_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// repeating_event => /re/:id(.:format)
  // function(id, options)
  repeating_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// repeating_event_allocation => /re/:repeating_event_id/allocations/:id(.:format)
  // function(repeating_event_id, id, options)
  repeating_event_allocation_path: Utils.route([["repeating_event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"allocations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// repeating_event_allocations => /re/:repeating_event_id/allocations(.:format)
  // function(repeating_event_id, options)
  repeating_event_allocations_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"allocations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_event_edit_allocations => /re/:repeating_event_id/edit_allocations(.:format)
  // function(repeating_event_id, options)
  repeating_event_edit_allocations_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"edit_allocations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_event_exceptions => /repeating_event_exceptions(.:format)
  // function(options)
  repeating_event_exceptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_event_exceptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// repeating_event_expire => /re/:repeating_event_id/expire(.:format)
  // function(repeating_event_id, options)
  repeating_event_expire_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"expire",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_event_form => /repeating_event_form(.:format)
  // function(options)
  repeating_event_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_event_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// repeating_event_message => /re/:repeating_event_id/messages/:id(.:format)
  // function(repeating_event_id, id, options)
  repeating_event_message_path: Utils.route([["repeating_event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// repeating_event_messages => /re/:repeating_event_id/messages(.:format)
  // function(repeating_event_id, options)
  repeating_event_messages_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_event_update_allocations => /re/:repeating_event_id/update_allocations(.:format)
  // function(repeating_event_id, options)
  repeating_event_update_allocations_path: Utils.route([["repeating_event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"repeating_event_id",false],[2,[7,"/",false],[2,[6,"update_allocations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_events => /re(.:format)
  // function(options)
  repeating_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"re",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// repeating_events_date_from_interval => /repeating_events/date_from_interval/:id(.:format)
  // function(id, options)
  repeating_events_date_from_interval_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"date_from_interval",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_events_date_from_interval_index => /repeating_events/date_from_interval(.:format)
  // function(options)
  repeating_events_date_from_interval_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"date_from_interval",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// repeating_events_events_to_delete => /repeating_events/events_to_delete/:id(.:format)
  // function(id, options)
  repeating_events_events_to_delete_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"events_to_delete",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_events_events_to_delete_index => /repeating_events/events_to_delete(.:format)
  // function(options)
  repeating_events_events_to_delete_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"events_to_delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// repeating_events_group => /repeating_events/groups/:id(.:format)
  // function(id, options)
  repeating_events_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// repeating_events_group_update => /repeating_events/group_update(.:format)
  // function(options)
  repeating_events_group_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"group_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// repeating_events_groups => /repeating_events/groups(.:format)
  // function(options)
  repeating_events_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// repeating_events_load_index => /repeating_events/load_index(.:format)
  // function(options)
  repeating_events_load_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"repeating_events",false],[2,[7,"/",false],[2,[6,"load_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_access_outside_working_hours_generate => /reports/access_outside_working_hours/generate(.:format)
  // function(options)
  reports_access_outside_working_hours_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"access_outside_working_hours",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_access_outside_working_hours_index => /reports/access_outside_working_hours(.:format)
  // function(options)
  reports_access_outside_working_hours_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"access_outside_working_hours",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_activities => /reports/activities(.:format)
  // function(options)
  reports_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_activity => /reports/activities/:id(.:format)
  // function(id, options)
  reports_activity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_acuity_report => /reports/booking_acuity_scores(.:format)
  // function(options)
  reports_acuity_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"booking_acuity_scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_attendance_map => /reports/map(.:format)
  // function(options)
  reports_attendance_map_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_auto_vs_manual_scheduling_create => /reports/auto_vs_manual_scheduling/create(.:format)
  // function(options)
  reports_auto_vs_manual_scheduling_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"auto_vs_manual_scheduling",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_auto_vs_manual_scheduling_index => /reports/auto_vs_manual_scheduling/index(.:format)
  // function(options)
  reports_auto_vs_manual_scheduling_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"auto_vs_manual_scheduling",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_availability_index => /reports/availability_capacity/index(.:format)
  // function(options)
  reports_availability_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"availability_capacity",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_bury_council_download => /reports/bury/report(.:format)
  // function(options)
  reports_bury_council_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"bury",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_bury_council_index => /reports/bury(.:format)
  // function(options)
  reports_bury_council_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"bury",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_call_monitoring_alert_download => /reports/call_monitoring_alert/download(.:format)
  // function(options)
  reports_call_monitoring_alert_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"call_monitoring_alert",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_call_monitoring_alert_new => /reports/call_monitoring_alert/new(.:format)
  // function(options)
  reports_call_monitoring_alert_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"call_monitoring_alert",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_caseload_generate => /reports/caseload/report(.:format)
  // function(options)
  reports_caseload_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"caseload",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_caseload_index => /reports/caseload(.:format)
  // function(options)
  reports_caseload_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"caseload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_clients_report_index => /reports/clients_report/index(.:format)
  // function(options)
  reports_clients_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"clients_report",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_continuity_ajax => /reports/continuity/ajax(.:format)
  // function(options)
  reports_continuity_ajax_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"continuity",false],[2,[7,"/",false],[2,[6,"ajax",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_continuity_download => /reports/continuity/download(.:format)
  // function(options)
  reports_continuity_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"continuity",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_continuity_new => /reports/continuity/new(.:format)
  // function(options)
  reports_continuity_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"continuity",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_default_user_index => /reports/default_user(.:format)
  // function(options)
  reports_default_user_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"default_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_dna_report_index => /reports/dna_report/index(.:format)
  // function(options)
  reports_dna_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"dna_report",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_duplicate_nhs_numbers => /reports/duplicate_nhs_numbers(.:format)
  // function(options)
  reports_duplicate_nhs_numbers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"duplicate_nhs_numbers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_emerge_sage => /reports/emerge_sage(.:format)
  // function(options)
  reports_emerge_sage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"emerge_sage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_hillingdon_council_download => /reports/hillingdon/report(.:format)
  // function(options)
  reports_hillingdon_council_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"hillingdon",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_hillingdon_council_index => /reports/hillingdon(.:format)
  // function(options)
  reports_hillingdon_council_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"hillingdon",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_incompatibilities_download => /reports/incompatibilities/download(.:format)
  // function(options)
  reports_incompatibilities_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_item => /reports/items/:id(.:format)
  // function(id, options)
  reports_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_items => /reports/items(.:format)
  // function(options)
  reports_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_manchester_council_download => /reports/manchester/report(.:format)
  // function(options)
  reports_manchester_council_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"manchester",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_manchester_council_index => /reports/manchester(.:format)
  // function(options)
  reports_manchester_council_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"manchester",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_message_ajax => /reports/message/ajax(.:format)
  // function(options)
  reports_message_ajax_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"message",false],[2,[7,"/",false],[2,[6,"ajax",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_message_download => /reports/message/download(.:format)
  // function(options)
  reports_message_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"message",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_message_new => /reports/message/new(.:format)
  // function(options)
  reports_message_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"message",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_mileage => /reports/mileage(.:format)
  // function(options)
  reports_mileage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"mileage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_mileage_calculate => /reports/mileage/calculate(.:format)
  // function(options)
  reports_mileage_calculate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"mileage",false],[2,[7,"/",false],[2,[6,"calculate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_moved_event_index => /reports/moved_event/index(.:format)
  // function(options)
  reports_moved_event_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"moved_event",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_nhs_mileage => /reports/nhs_mileage(.:format)
  // function(options)
  reports_nhs_mileage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"nhs_mileage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_nhs_mileage_generate => /reports/nhs_mileage/generate(.:format)
  // function(options)
  reports_nhs_mileage_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"nhs_mileage",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_responses => /reports/responses(.:format)
  // function(options)
  reports_responses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_route_report_download => /reports/route_report/report(.:format)
  // function(options)
  reports_route_report_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"route_report",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_route_report_index => /reports/route_report(.:format)
  // function(options)
  reports_route_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"route_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_shared_residence_report_index => /reports/shared_residence_report/index(.:format)
  // function(options)
  reports_shared_residence_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"shared_residence_report",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_sms => /reports/sms/new(.:format)
  // function(options)
  reports_sms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"sms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_sms_download => /reports/sms/download(.:format)
  // function(options)
  reports_sms_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"sms",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_sthelens_council_download => /reports/sthelens/report(.:format)
  // function(options)
  reports_sthelens_council_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"sthelens",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_sthelens_council_index => /reports/sthelens(.:format)
  // function(options)
  reports_sthelens_council_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"sthelens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_stoke_on_trent_council_index => /reports/stoke_on_trent(.:format)
  // function(options)
  reports_stoke_on_trent_council_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"stoke_on_trent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_total_hours_index => /reports/total_hours(.:format)
  // function(options)
  reports_total_hours_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"total_hours",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_training => /reports/training(.:format)
  // function(options)
  reports_training_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"training",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_training_new => /reports/training/new(.:format)
  // function(options)
  reports_training_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"training",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_user_accepted_items_generate => /reports/user_accepted_items/generate(.:format)
  // function(options)
  reports_user_accepted_items_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_accepted_items",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_user_accepted_items_index => /reports/user_accepted_items(.:format)
  // function(options)
  reports_user_accepted_items_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_accepted_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_user_event => /reports/user_events/:id(.:format)
  // function(id, options)
  reports_user_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_user_events => /reports/user_events(.:format)
  // function(options)
  reports_user_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_user_exceeding_maximum_daily_contact_minutes_generate => /reports/user_exceeding_maximum_daily_contact_minutes/generate(.:format)
  // function(options)
  reports_user_exceeding_maximum_daily_contact_minutes_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_exceeding_maximum_daily_contact_minutes",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_user_exceeding_maximum_daily_contact_minutes_index => /reports/user_exceeding_maximum_daily_contact_minutes/index(.:format)
  // function(options)
  reports_user_exceeding_maximum_daily_contact_minutes_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_exceeding_maximum_daily_contact_minutes",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_users_get_event => /reports/users/get_event(.:format)
  // function(options)
  reports_users_get_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"get_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_users_report_index => /reports/users_report/index(.:format)
  // function(options)
  reports_users_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"users_report",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_visit_activity_generate => /reports/visit_activity/generate(.:format)
  // function(options)
  reports_visit_activity_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"visit_activity",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_visit_activity_new => /reports/visit_activity/new(.:format)
  // function(options)
  reports_visit_activity_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"visit_activity",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_visit_sequence_compliance_generate => /reports/visit_sequence_compliance/generate(.:format)
  // function(options)
  reports_visit_sequence_compliance_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"visit_sequence_compliance",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_visit_sequence_compliance_index => /reports/visit_sequence_compliance(.:format)
  // function(options)
  reports_visit_sequence_compliance_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"visit_sequence_compliance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_voice_messages_index => /reports/voice_messages(.:format)
  // function(options)
  reports_voice_messages_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"voice_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// responses_import => /responses/import/:id(.:format)
  // function(id, options)
  responses_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// responses_import_index => /responses/import(.:format)
  // function(options)
  responses_import_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// routing => /routing(.:format)
  // function(options)
  routing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"routing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// routing_worker => /routing_workers/:id(.:format)
  // function(id, options)
  routing_worker_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"routing_workers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// routing_workers => /routing_workers(.:format)
  // function(options)
  routing_workers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"routing_workers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// score_multiplier => /score_multipliers/:id(.:format)
  // function(id, options)
  score_multiplier_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"score_multipliers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// score_multipliers => /score_multipliers(.:format)
  // function(options)
  score_multipliers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"score_multipliers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// search => /search/:id(.:format)
  // function(id, options)
  search_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search_index => /search(.:format)
  // function(options)
  search_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// selenity_config => /selenity_configs/:id(.:format)
  // function(id, options)
  selenity_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"selenity_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// selenity_configs => /selenity_configs(.:format)
  // function(options)
  selenity_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"selenity_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// selenity_import_mileage_categories => /selenity_import_mileage_categories(.:format)
  // function(options)
  selenity_import_mileage_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"selenity_import_mileage_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// selenity_update_additional_settings => /selenity_update_additional_settings(.:format)
  // function(options)
  selenity_update_additional_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"selenity_update_additional_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// setting => /settings/:id(.:format)
  // function(id, options)
  setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setting_update_mars_text => /settings/:setting_id/update_mars_text(.:format)
  // function(setting_id, options)
  setting_update_mars_text_path: Utils.route([["setting_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"setting_id",false],[2,[7,"/",false],[2,[6,"update_mars_text",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings => /settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// show_repeating_events => /show_repeating_events(.:format)
  // function(options)
  show_repeating_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"show_repeating_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sidekiq_users_sign_in => /sidekiq/users/sign_in(.:format)
  // function(options)
  sidekiq_users_sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sidekiq",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sign_out_user => /users/sign_out(.:format)
  // function(options)
  sign_out_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// site => /s/:id(.:format)
  // function(id, options)
  site_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// site_archive => /s/:site_id/archive(.:format)
  // function(site_id, options)
  site_archive_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site_event => /s/:site_id/e/:id(.:format)
  // function(site_id, id, options)
  site_event_path: Utils.route([["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// site_event_collection => /s/:site_id/e/:event_id/collections/:id(.:format)
  // function(site_id, event_id, id, options)
  site_event_collection_path: Utils.route([["site_id",true],["event_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// site_event_collections => /s/:site_id/e/:event_id/collections(.:format)
  // function(site_id, event_id, options)
  site_event_collections_path: Utils.route([["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// site_event_edit_response => /s/:site_id/e/:event_id/edit_response(.:format)
  // function(site_id, event_id, options)
  site_event_edit_response_path: Utils.route([["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"edit_response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// site_event_update_response => /s/:site_id/e/:event_id/update_response(.:format)
  // function(site_id, event_id, options)
  site_event_update_response_path: Utils.route([["site_id",true],["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"event_id",false],[2,[7,"/",false],[2,[6,"update_response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// site_events => /s/:site_id/e(.:format)
  // function(site_id, options)
  site_events_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"e",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site_export => /s/:site_id/export(.:format)
  // function(site_id, options)
  site_export_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site_jobsheet_download => /site/jobsheets/download(.:format)
  // function(options)
  site_jobsheet_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site",false],[2,[7,"/",false],[2,[6,"jobsheets",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site_jobsheets => /site/jobsheets(.:format)
  // function(options)
  site_jobsheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site",false],[2,[7,"/",false],[2,[6,"jobsheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// site_message => /s/:site_id/messages/:id(.:format)
  // function(site_id, id, options)
  site_message_path: Utils.route([["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// site_messages => /s/:site_id/messages(.:format)
  // function(site_id, options)
  site_messages_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site_repeating_event => /s/:site_id/re/:id(.:format)
  // function(site_id, id, options)
  site_repeating_event_path: Utils.route([["site_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// site_repeating_events => /s/:site_id/re(.:format)
  // function(site_id, options)
  site_repeating_events_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"re",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site_unarchive => /s/:site_id/unarchive(.:format)
  // function(site_id, options)
  site_unarchive_path: Utils.route([["site_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[2,[7,"/",false],[2,[3,"site_id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites => /s(.:format)
  // function(options)
  sites_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sites_add_client_field => /sites_add_client_field(.:format)
  // function(options)
  sites_add_client_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites_add_client_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sites_copy_events_create => /sites/copy_events/create(.:format)
  // function(options)
  sites_copy_events_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"copy_events",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites_copy_events_target_site_has_events => /sites/copy_events/target_site_has_events(.:format)
  // function(options)
  sites_copy_events_target_site_has_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"copy_events",false],[2,[7,"/",false],[2,[6,"target_site_has_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites_planner => /sites/planner/:id(.:format)
  // function(id, options)
  sites_planner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"planner",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites_planner_get_event => /sites/planner/get_event(.:format)
  // function(options)
  sites_planner_get_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"planner",false],[2,[7,"/",false],[2,[6,"get_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites_planner_index => /sites/planner(.:format)
  // function(options)
  sites_planner_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"planner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sites_week => /sites/week/:id(.:format)
  // function(id, options)
  sites_week_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"week",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites_week_index => /sites/week(.:format)
  // function(options)
  sites_week_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"week",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sms_message => /sms_messages/:id(.:format)
  // function(id, options)
  sms_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sms_messages => /sms_messages(.:format)
  // function(options)
  sms_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sms_template => /sms_templates/:id(.:format)
  // function(id, options)
  sms_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sms_templates => /sms_templates(.:format)
  // function(options)
  sms_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sms_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// suggest_users_table => /suggest_users_table(.:format)
  // function(options)
  suggest_users_table_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"suggest_users_table",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// suggest_users_table_add_remove_user => /suggest_users_table_add_remove_user(.:format)
  // function(options)
  suggest_users_table_add_remove_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"suggest_users_table_add_remove_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// suggest_users_table_check_clash => /suggest_users_table_check_clash(.:format)
  // function(options)
  suggest_users_table_check_clash_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"suggest_users_table_check_clash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// suggest_users_table_check_user_problems => /suggest_users_table_check_user_problems(.:format)
  // function(options)
  suggest_users_table_check_user_problems_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"suggest_users_table_check_user_problems",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// suggest_users_table_distance_from_last_event => /suggest_users_table_distance_from_last_event(.:format)
  // function(options)
  suggest_users_table_distance_from_last_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"suggest_users_table_distance_from_last_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// suggest_users_table_view_score => /suggest_users_table_view_score(.:format)
  // function(options)
  suggest_users_table_view_score_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"suggest_users_table_view_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// thirdparty_care_is => /thirdparty/care_is(.:format)
  // function(options)
  thirdparty_care_is_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"care_is",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// thirdparty_care_is_import_patient => /thirdparty/care_is(.:format)
  // function(options)
  thirdparty_care_is_import_patient_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"care_is",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// thirdparty_care_is_write_back => /thirdparty/care_is/write_back/:for(.:format)
  // function(for, options)
  thirdparty_care_is_write_back_path: Utils.route([["for",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"care_is",false],[2,[7,"/",false],[2,[6,"write_back",false],[2,[7,"/",false],[2,[3,"for",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// thirdparty_selenity => /thirdparty/selenity(.:format)
  // function(options)
  thirdparty_selenity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"selenity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// thirdparty_selenity_index => /thirdparty/selenity_index(.:format)
  // function(options)
  thirdparty_selenity_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"selenity_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// thirdparty_selenity_manual_export => /thirdparty/selenity/manual_export(.:format)
  // function(options)
  thirdparty_selenity_manual_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"selenity",false],[2,[7,"/",false],[2,[6,"manual_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_submit_selenity_manual_export => /thirdparty/selenity/manual_export(.:format)
  // function(options)
  thirdparty_submit_selenity_manual_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"selenity",false],[2,[7,"/",false],[2,[6,"manual_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare => /thirdparty/xycare(.:format)
  // function(options)
  thirdparty_xycare_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// thirdparty_xycare_check_server_api_status => /thirdparty/xycare/check_server_api_status(.:format)
  // function(options)
  thirdparty_xycare_check_server_api_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"check_server_api_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare_generate_app_device_ticket => /thirdparty/xycare/generate_app_device_ticket(.:format)
  // function(options)
  thirdparty_xycare_generate_app_device_ticket_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"generate_app_device_ticket",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare_import_patient => /thirdparty/xycare(.:format)
  // function(options)
  thirdparty_xycare_import_patient_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// thirdparty_xycare_log_error => /thirdparty/xycare/log_error(.:format)
  // function(options)
  thirdparty_xycare_log_error_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"log_error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare_log_pms_connections => /thirdparty/xycare/log_pms_connections(.:format)
  // function(options)
  thirdparty_xycare_log_pms_connections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"log_pms_connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare_patient_diff => /thirdparty/xycare/patient_diff(.:format)
  // function(options)
  thirdparty_xycare_patient_diff_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"patient_diff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare_write_back => /thirdparty/xycare/write_back(.:format)
  // function(options)
  thirdparty_xycare_write_back_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"write_back",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thirdparty_xycare_write_back_create_activity => /thirdparty/xycare/write_back/create_activity(.:format)
  // function(options)
  thirdparty_xycare_write_back_create_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"write_back",false],[2,[7,"/",false],[2,[6,"create_activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// thirdparty_xycare_write_back_log_request => /thirdparty/xycare/write_back/log_request(.:format)
  // function(options)
  thirdparty_xycare_write_back_log_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"xycare",false],[2,[7,"/",false],[2,[6,"write_back",false],[2,[7,"/",false],[2,[6,"log_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// thirdparty_zendesk_sign_in => /thirdparty/zendesk/sign_in(.:format)
  // function(options)
  thirdparty_zendesk_sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"thirdparty",false],[2,[7,"/",false],[2,[6,"zendesk",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// training => /trainings/:id(.:format)
  // function(id, options)
  training_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// training_archive => /trainings/:training_id/archive(.:format)
  // function(training_id, options)
  training_archive_path: Utils.route([["training_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"training_id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// training_trainings_new => /trainings/:training_id/trainings/new(.:format)
  // function(training_id, options)
  training_trainings_new_path: Utils.route([["training_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"training_id",false],[2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// trainings => /trainings(.:format)
  // function(options)
  trainings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trait => /traits/:id(.:format)
  // function(id, options)
  trait_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"traits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// traits => /traits(.:format)
  // function(options)
  traits_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"traits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// traits_user => /traits_users/:id(.:format)
  // function(id, options)
  traits_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"traits_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// traits_users => /traits_users(.:format)
  // function(options)
  traits_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"traits_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// traits_users_destroy => /traits_users_destroy(.:format)
  // function(options)
  traits_users_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"traits_users_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// twilio => /twilio/:account_name/:user_name/next_event_alert(.:format)
  // function(account_name, user_name, options)
  twilio_path: Utils.route([["account_name",true],["user_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"twilio",false],[2,[7,"/",false],[2,[3,"account_name",false],[2,[7,"/",false],[2,[3,"user_name",false],[2,[7,"/",false],[2,[6,"next_event_alert",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// unarchive_item => /i/:id/unarchive(.:format)
  // function(id, options)
  unarchive_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// uncancellation => /uncancel(.:format)
  // function(options)
  uncancellation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"uncancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_auto_scheduler_config => /configure/auto_scheduler_configs(.:format)
  // function(options)
  update_auto_scheduler_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"configure",false],[2,[7,"/",false],[2,[6,"auto_scheduler_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_user_password => /update_user_password(.:format)
  // function(options)
  update_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"update_user_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_availabilities => /users/:user_id/availabilities(.:format)
  // function(user_id, options)
  user_availabilities_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"availabilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_availability => /users/:user_id/availabilities/:id(.:format)
  // function(user_id, id, options)
  user_availability_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"availabilities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_avatar => /users/:user_id/avatar(.:format)
  // function(user_id, options)
  user_avatar_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"avatar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_check_incompatibilities => /users/:user_id/check_incompatibilities(.:format)
  // function(user_id, options)
  user_check_incompatibilities_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"check_incompatibilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_collections => /reports/user_collections(.:format)
  // function(options)
  user_collections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"user_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_default_availabilities => /users/:user_id/default_availabilities(.:format)
  // function(user_id, options)
  user_default_availabilities_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"default_availabilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_default_availabilities_add_time_field => /users/:user_id/default_availabilities_add_time_field(.:format)
  // function(user_id, options)
  user_default_availabilities_add_time_field_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"default_availabilities_add_time_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_default_availabilities_week_commencing => /users/:user_id/default_availabilities/week_commencing(.:format)
  // function(user_id, options)
  user_default_availabilities_week_commencing_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"default_availabilities",false],[2,[7,"/",false],[2,[6,"week_commencing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_default_availability => /users/:user_id/default_availabilities/:id(.:format)
  // function(user_id, id, options)
  user_default_availability_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"default_availabilities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_disable_2fa => /users/:user_id/disable_2fa(.:format)
  // function(user_id, options)
  user_disable_2fa_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"disable_2fa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_enroll_2fa => /user/enroll_2fa(.:format)
  // function(options)
  user_enroll_2fa_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"enroll_2fa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_event => /users/:user_id/e/:id(.:format)
  // function(user_id, id, options)
  user_event_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_events => /users/:user_id/e(.:format)
  // function(user_id, options)
  user_events_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"e",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_incompatibilities => /users/:user_id/incompatibilities(.:format)
  // function(user_id, options)
  user_incompatibilities_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_incompatibility => /users/:user_id/incompatibilities/:id(.:format)
  // function(user_id, id, options)
  user_incompatibility_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_incompatibility_expire => /users/:user_id/incompatibilities/:incompatibility_id/expire(.:format)
  // function(user_id, incompatibility_id, options)
  user_incompatibility_expire_path: Utils.route([["user_id",true],["incompatibility_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"incompatibilities",false],[2,[7,"/",false],[2,[3,"incompatibility_id",false],[2,[7,"/",false],[2,[6,"expire",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_jobsheet_download => /user/jobsheets/download(.:format)
  // function(options)
  user_jobsheet_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"jobsheets",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_jobsheets => /user/jobsheets(.:format)
  // function(options)
  user_jobsheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"jobsheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_message => /users/:user_id/messages/:id(.:format)
  // function(user_id, id, options)
  user_message_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_messages => /users/:user_id/messages(.:format)
  // function(user_id, options)
  user_messages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_move_events => /users/:user_id/move_events(.:format)
  // function(user_id, options)
  user_move_events_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"move_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password_expired => /users/password_expired(.:format)
  // function(options)
  user_password_expired_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password_expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_selenity_expense_items => /users/:user_id/selenity_expense_items(.:format)
  // function(user_id, options)
  user_selenity_expense_items_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"selenity_expense_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_single_availabilities => /users/:user_id/single_availabilities(.:format)
  // function(user_id, options)
  user_single_availabilities_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"single_availabilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_single_availabilities_add_time_field => /users/:user_id/single_availabilities_add_time_field(.:format)
  // function(user_id, options)
  user_single_availabilities_add_time_field_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"single_availabilities_add_time_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_single_availability => /users/:user_id/single_availabilities/:id(.:format)
  // function(user_id, id, options)
  user_single_availability_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"single_availabilities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users_archive => /users/:id/archive(.:format)
  // function(id, options)
  users_archive_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_archive_index => /users/archive_index(.:format)
  // function(options)
  users_archive_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"archive_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_availabilities => /users/availabilities(.:format)
  // function(options)
  users_availabilities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"availabilities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_check_max_minutes => /users/check_max_minutes(.:format)
  // function(options)
  users_check_max_minutes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"check_max_minutes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_collect => /users/collect/:id(.:format)
  // function(id, options)
  users_collect_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"collect",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_collect_index => /users/collect(.:format)
  // function(options)
  users_collect_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"collect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week => /users/compact_week/:id(.:format)
  // function(id, options)
  users_compact_week_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_compact_week_download_print => /users/compact_week_download_print(.:format)
  // function(options)
  users_compact_week_download_print_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_download_print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_edit_time => /users/compact_week_edit_time(.:format)
  // function(options)
  users_compact_week_edit_time_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_edit_time",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_edit_time_fields => /users/compact_week_edit_time_fields(.:format)
  // function(options)
  users_compact_week_edit_time_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_edit_time_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_index => /users/compact_week_index(.:format)
  // function(options)
  users_compact_week_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_line_item => /users/compact_week_line_item(.:format)
  // function(options)
  users_compact_week_line_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_line_item",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_shift_details => /users/compact_week_shift_details(.:format)
  // function(options)
  users_compact_week_shift_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_shift_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_show_event_row => /users/compact_week_show_event_row(.:format)
  // function(options)
  users_compact_week_show_event_row_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_show_event_row",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_compact_week_show_events_table => /users/compact_week_show_events_table(.:format)
  // function(options)
  users_compact_week_show_events_table_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"compact_week_show_events_table",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_day_load_user_cell => /users/day_load_user_cell(.:format)
  // function(options)
  users_day_load_user_cell_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"day_load_user_cell",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_day_load_user_row => /users/day_load_user_row(.:format)
  // function(options)
  users_day_load_user_row_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"day_load_user_row",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_events_batch_jobsheet => /reports/users/day/jobsheet/:user_id/:date(.:format)
  // function(user_id, date, options)
  users_events_batch_jobsheet_path: Utils.route([["user_id",true],["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"day",false],[2,[7,"/",false],[2,[6,"jobsheet",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// users_import_accepted_items_create => /users/items/accepted/import/create(.:format)
  // function(options)
  users_import_accepted_items_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"accepted",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// users_import_accepted_items_index => /users/items/accepted/import(.:format)
  // function(options)
  users_import_accepted_items_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"accepted",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// users_import_accepted_items_new => /users/items/accepted/import/new(.:format)
  // function(options)
  users_import_accepted_items_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"accepted",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// users_import_selenity_usernames_index => /users/usernames/selenity/import(.:format)
  // function(options)
  users_import_selenity_usernames_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"usernames",false],[2,[7,"/",false],[2,[6,"selenity",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// users_import_selenity_usernames_new => /users/usernames/selenity/import/new(.:format)
  // function(options)
  users_import_selenity_usernames_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"usernames",false],[2,[7,"/",false],[2,[6,"selenity",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// users_live_status => /users/live_status(.:format)
  // function(options)
  users_live_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"live_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_unarchive => /users/:id/unarchive(.:format)
  // function(id, options)
  users_unarchive_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_week_view => /week(.:format)
  // function(options)
  users_week_view_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"week",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// week_print => /week_print(.:format)
  // function(options)
  week_print_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"week_print",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
