import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { add } from "date-fns";

import "./style.scss";

import CurrentDayView from "../current_day_view/CurrentDayView";
import InterventionDemandSection from "../intervention_demand_section/InterventionDemandSection";
import CapacityDetailSection from "../capacity_detail_section/CapacityDetailSection";

function TabPanel(props) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel`}
      aria-labelledby={`simple-tab`}
      {...other}
    >
      {<Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    key: index,
  };
}

export const DayViewWrapper = ({ dailyData, labels, dateFrom, handleDailyDataSubmit,
  dailyBreakdownTabValue, setDailyBreakdownTabValue }) => {
  const handleDailyBreakdownTabChange = (event, newValue) => {
    setDailyBreakdownTabValue(newValue);
  };

  return (
    <>
      {dailyData && labels && (
        <div className="day-view-wrapper">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={dailyBreakdownTabValue}
              onChange={handleDailyBreakdownTabChange}
              aria-label="day view tabs"
              variant="fullWidth"
            >
              {labels.map((label, index) => {
                const dateArray = label.split("-");

                const dateWrapper = dateArray.map((date, index) =>
                    <span key={index}>{date} </span>
                );

                const tabDate = add(dateFrom, { days: index });

                return <Tab label={<div className="date-wrapper">{dateWrapper}</div>} onClick={()=>handleDailyDataSubmit(tabDate)}
                  {...a11yProps(index)} />;
              })}
            </Tabs>
          </Box>
          <TabPanel>
            <CurrentDayView dayData={dailyData} />
            <InterventionDemandSection interventionDemand={dailyData.interventionDemand} />
            <CapacityDetailSection staffBands={dailyData.staffBands} />
          </TabPanel>
        </div>
      )}
    </>
  );
};

export default DayViewWrapper;
